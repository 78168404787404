import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Accordion, Icon, Label } from 'semantic-ui-react';

class OrderHistory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeIndex: 0,
		};
	}

	render() {
		const { activeIndex } = this.state;
		return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0 main-title">결제 정보</h4>
          </div>

          <div className="col-auto align-self-end"></div>
        </div>
      </div>
    );
	}
}

export default withRouter(OrderHistory);
