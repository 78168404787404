import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import * as actions from 'actions';

const mapDispatchToProps = (dispatch) => ({
  validate: (user) => dispatch(actions.validate(user)),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /*
	static getDerivedStateFromProps(nextProps, prevState) {

		if (nextProps.user !== this.prop.user) {
			return {
				user: nextProps.user,
			};
		}
		return null;
	}
*/
  handleClickLogin = () => {
    window.showSubPage();
  };

  handleClickUserInfo = () => {
    window.showSubPage();
  };

  handleClickUsage = () => {
    window.showSubPage();
  };

  handleClickPayment = () => {
    window.showSubPage();
  };

  handleClickCoupon = () => {
    window.showSubPage();
  };

  handleClickWithdraw = () => {
    window.showSubPage();
  };

  handleClickJoin = () => {
    window.showSubPage();
  };

  handleClickShowSubPage = () => {
    window.showSubPage();
  };

  render() {
    const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
    //console.log(this.props);
    return (
      <div className="sidebar">
        <div className="row no-gutters">
          {(user === undefined || user === null) && (
            <div className="col-auto align-self-center">
              <Link
                to="/login"
                className="side-menu btn btn-link text-white p-2"
                onClick={this.handleClickLogin}
              >
                <Icon name="user outline" className="ml-1" /> 들어가기
              </Link>
            </div>
          )}
          {user !== undefined && user !== null && (
            <div className="col-auto align-self-center">
              <Link
                to="/userInfo"
                className="side-menu btn btn-link text-white p-2"
                onClick={this.handleClickUserInfo}
              >
                <Icon name="user" className="ml-1" /> {user.userEmail}
              </Link>
            </div>
          )}
        </div>
        <div className="list-group main-menu my-5">
          <Link
            to="/"
            className="side-menu list-group-item list-group-item-action active"
          >
            <Icon name="home" />
            홈으로
          </Link>
          <Link
            to="/notice"
            className="side-menu list-group-item list-group-item-action"
          >
            <Icon name="alarm" />
            알림 <span className="badge badge-dark text-white">1</span>
          </Link>
          <Link
            to="/company"
            className="side-menu list-group-item list-group-item-action"
          >
            <Icon name="building" />
            회사소개
          </Link>
          <Link
            to="/payment"
            className="side-menu list-group-item list-group-item-action"
          >
            <Icon name="payment" />
            구독권 구매
          </Link>
          {user !== undefined && user !== null && (
            <Fragment>
              <Link
                to="/usage"
                className="side-menu list-group-item list-group-item-action"
                onClick={this.handleClickUsage}
              >
                <Icon name="history" />
                사용정보
              </Link>
              <Link
                to="/order"
                className="side-menu list-group-item list-group-item-action"
                onClick={this.handleClickPayment}
              >
                <Icon name="won" />
                결제정보
              </Link>
              <Link
                to="/coupon"
                className="side-menu list-group-item list-group-item-action"
                onClick={this.handleClickCoupon}
              >
                <Icon name="copyright outline" />
                쿠폰정보
              </Link>
              <Link
                to="/withdraw"
                className="side-menu list-group-item list-group-item-action"
                onClick={this.handleClickWithdraw}
              >
                <Icon name="user delete" />
                회원탈퇴
              </Link>
            </Fragment>
          )}
          {(user === undefined || user === null) && (
            <Link
              to="/join"
              className="side-menu list-group-item list-group-item-action"
              onClick={this.handleClickJoin}
            >
              <Icon name="user plus" />
              회원가입
            </Link>
          )}
          <Link
            to="/compare"
            className="side-menu list-group-item list-group-item-action"
          >
            <Icon name="copy" />
            사전비교
          </Link>
        </div>

        {user !== undefined && user !== null && user.grade === 0 && (
          <div className="list-group main-menu my-5">
            <Link
              to="/mgrUser"
              className="side-menu list-group-item list-group-item-action"
              onClick={this.handleClickShowSubPage}
            >
              <Icon name="users" />
              회원관리
            </Link>
            <Link
              to="/mgrPayment"
              className="side-menu list-group-item list-group-item-action"
              onClick={this.handleClickShowSubPage}
            >
              <Icon name="won" />
              결제관리
            </Link>
            <Link
              to="/mgrCoupon"
              className="side-menu list-group-item list-group-item-action"
              onClick={this.handleClickShowSubPage}
            >
              <Icon name="cc" />
              쿠폰관리
            </Link>
            <Link
              to="/mgrStatistic"
              className="side-menu list-group-item list-group-item-action"
              onClick={this.handleClickShowSubPage}
            >
              <Icon name="area graph" />
              통계
            </Link>
          </div>
        )}

        {(user === undefined ||
          user === null ||
          (user !== undefined && user !== null && user.grade !== 0)) && (
          <div className="preview-content-wrapper">
            <div className="qr-code-wrapper">
              <div className="scan-me">
                <img src="/assets/images/qr/qrcode03.png" alt="" />
              </div>
              <h6 className="mb-0 mt-2">
                모바일기기에서 QR코드를 스캔하시면 바로 서비스에 접속할 수
                있습니다.
              </h6>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
