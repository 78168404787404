import React from 'react';

const Loader = () => {
  return (
    <div className="row no-gutters vh-100 loader-screen">
      <div className="bg-template background-overlay"></div>
      <div className="col align-self-center text-white text-center">
        {/* <img src="/assets/images/logos/Logo_40_CW.png" alt="logo" /> */}
        <img
          src="/assets/images/logos/wordnet-logo.png"
          style={{
            width: '132px',
          }}
          alt="logo"
        />
        <h2 className="mb-0 mt-3">Natmal Corporation</h2>
        <p className="text-mute subtitle">Thesaurus</p>
        <div className="loader-ractangls">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
