import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  Responsive,
  Icon,
  Button,
  Form,
  Grid,
  Message,
  Select,
  Divider,
  Pagination,
} from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as systemTypes from 'types/systemTypes';
import * as service from 'services/system';
import * as moment from 'moment';
import payService from 'services/payments';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class MgrUserDetailOrder extends Component {
  fetchListAll = (id) => {
    let used;
    payService
      .isUsedByManagerService({ user: id })
      .then((data) => {
        if (data.status) {
          used = data.used;
          this.setState({ used: data.used });
        }
      })
      .then(() => {
        if (used) {
          payService
            .getSubscribeInfomation({
              customer_uid: [
                'wordnet',
                used.WordnetItem.ITEM_ID,
                used.USER_ID,
              ].join('_'),
            })
            .then((res) => {
              console.log('🚀 ~ MgrUserDetailOrder ~ .then ~ res:', res);
              console.log(res);
              if (res.status === 200) {
                this.setState({ subscribeStatus: res.data.STATUS });
              }
            });
        }
      });
  };

  constructor(props) {
    super(props);

    const state = {
      userItem: null,
      userId: 0,
      userEmail: '',
      userName: '',
      userPhone: '',
      userCheckEmail: '',
      userAuth: '',
      changeCheckEmail: 0,
      changeUserAuth: 0,
      used: null,
    };

    let v = {
      couponStatus: {
        error: false,
        message: '쿠폰상태를 선택해 주세요.',
      },
    };

    let typeOption = Object.keys(systemTypes.ORDER_TYPES).map((key) => {
      let item = systemTypes.ORDER_TYPES[key];
      return {
        key: item.id,
        text: item.title,
        value: item.value,
        style: { fontSize: '0.9em' },
      };
    });

    //console.log(props);
    const userItem =
      props.location.post !== undefined && props.location.post !== null
        ? props.location.post.userItem
        : null;
    state.userItem = userItem;
    state.userId = userItem && userItem.ID !== null ? userItem.ID : '';
    state.userEmail =
      userItem && userItem.USEREMAIL !== null ? userItem.USEREMAIL.trim() : '';
    state.userName =
      userItem && userItem.USERNAME !== null ? userItem.USERNAME.trim() : '';

    this.state = {
      isWarning: false,
      ...state,
      typeOption,
      orderType: 0,
      resultData: [],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
    };
  }

  componentDidMount() {
    const user = this.props.user;
    //console.log(user);
    this.fetchListAll(this.state.userId);
    if (user !== undefined && user !== null && user.grade === 0) {
      this.fetchUserOrderItems({
        userId: this.state.userId,
        pageSize: this.state.pageSize,
        pageNum: this.state.currentPage,
      });
    }
  }

  handleOnClickGoBack = () => {
    this.props.history.goBack();
  };

  typeHandler = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
    this.setState({ isWarning: false });
  };

  onSumbit = (e) => {
    e.preventDefault();
    if (this.state.orderType > 0) {
      let { userId, orderType } = this.state;
      let datas = { userId, orderType };
      this.fetchInsertUserOrderItem(datas);
    } else {
      Toastr.error('등록할 상품을 선택해 주세요.');
    }
  };

  fetchInsertUserOrderItem = async (params) => {
    const orderItem = await service.insertUserOrderItem(params);
  };

  fetchUserOrderItems = async (params) => {
    const orderItems = await service.selectUserOrderItems(params);
    //console.log(orderItems);
    if (orderItems !== null) {
      if (orderItems.status === 200) {
        if (orderItems.data) {
          this.showOrderItems(orderItems.data);
        } else {
          this.setState({
            resultData: [],
            totalPage: 0,
            totalElements: 0,
          });
        }
      } else {
        Toastr.error(
          '주문 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.'
        );
      }
    } else {
      Toastr.error(
        '주문 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.'
      );
    }
  };

  showOrderItems(data) {
    const totalPage = Math.ceil(parseInt(data.total) / this.state.pageSize);
    //if (this._isMounted) {
    this.setState({
      total: data.total,
      totalPages: totalPage,
      resultData: data.datas,
    });
    //}
  }

  onClickSubscribeCancel = (e) => {
    e.preventDefault();
    const { used } = this.state;
    if (used) {
      payService
        .getSubscribeInfomation({
          customer_uid: [
            'wordnet',
            used.WordnetItem.ITEM_ID,
            used.USER_ID,
          ].join('_'),
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);

            //정기 결제 해지
            if (window.confirm('정기 결제를 해지 하시겠습니까?')) {
              console.log('정기 결제 해지');
              payService
                .userCancelSubscribe({
                  customer_uid: [
                    'natmal',
                    used.Item.ITEM_ID,
                    used.USER_ID,
                  ].join('_'),
                })
                .then((res) => {
                  if (res.status === 200) {
                    this.fetchListAll(this.state.userId);
                    alert(res.message);
                  }
                });
            }
          }
        });
    }
  };

  render() {
    const {
      userEmail,
      userName,
      resultData,
      typeOption,
      orderType,
      isWarning,
      errors,
      currentPage,
      pageSize,
      totalPages,
      used,
    } = this.state;

    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col">
            <Button
              size="tiny"
              circular
              icon="arrow left"
              onClick={this.handleOnClickGoBack}
            />
            <span className="ml-3 mt-1 text-13 font-600">회원 주문정보</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {used && (
              <div
                className="alert alert-info alert-light alert-dismissible"
                role="alert"
              >
                <i className="color-info zmdi zmdi-info" /> 현재&nbsp;「
                {this.state.used.WordnetItem.NAME}」을 사용하고 있습니다.
                {this.state.used.WordnetItem.ITEM_ID !== 6 && (
                  <span>
                    서비스 기간:{' '}
                    {moment(this.state.used.START_DATE).format('YYYY-MM-DD')}~
                    {moment(this.state.used.END_DATE).format('YYYY-MM-DD')}
                  </span>
                )}
                {this.state.used.WordnetItem.ITEM_ID === 6 && (
                  <div className="float-right">
                    {this.state.subscribeStatus && (
                      <Button onClick={this.onClickSubscribeCancel}>
                        정기 결제 해지
                      </Button>
                    )}
                    {this.state.subscribeStatus &&
                      '구독을 취소 하셨습니다. 남은 일수 이후 서비스가 만료 됩니다.'}
                  </div>
                )}
              </div>
            )}
            {!used && (
              <div
                className="alert alert-primary alert-light alert-dismissible"
                role="alert"
              >
                <i className="color-primary zmdi zmdi-info" /> 현재 사용 중인
                이용권이 없습니다.
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mx-auto">
            <Form warning={isWarning} key="big" size="big">
              <Grid>
                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column>
                    <Message
                      warning
                      header="아래 사항을 확인해 주세요."
                      list={errors}
                    />

                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="이메일"
                          fluid
                          name="userEmail"
                          value={userEmail}
                          readOnly
                        />
                      </Form.Field>

                      <Form.Field width={8}>
                        <Form.Input
                          label="사용자명"
                          fluid
                          name="userName"
                          value={userName}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Divider />

                    <Form.Group inline>
                      <Form.Field
                        required
                        width={16}
                        style={{ padding: '0px' }}
                      >
                        <label>상품명</label>
                        <Select
                          name="orderType"
                          options={typeOption}
                          placeholder="주문상품종류"
                          style={{ fontSize: '0.9em' }}
                          onChange={this.typeHandler}
                          value={orderType}
                        />
                        <Button
                          className="ml-1"
                          size="large"
                          children="신규주문추가"
                          color="teal"
                          onClick={this.onSumbit}
                        />
                      </Form.Field>
                      <Form.Field>&nbsp;</Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column>
                    <Table
                      key="black"
                      fixed
                      selectable
                      textAlign="center"
                      style={{
                        position: 'relative',
                        padding: '0px',
                        margin: '0px',
                      }}
                    >
                      <Responsive as={Table.Header} minWidth={768}>
                        <Table.Row>
                          <Table.HeaderCell width="2">No.</Table.HeaderCell>
                          <Table.HeaderCell width="6">상품명</Table.HeaderCell>
                          <Table.HeaderCell width="2">시작일</Table.HeaderCell>
                          <Table.HeaderCell width="2">종료일</Table.HeaderCell>
                          <Table.HeaderCell width="2">정지일</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center" width={2}>
                            상태
                          </Table.HeaderCell>
                        </Table.Row>
                      </Responsive>
                      <Table.Body>
                        {resultData &&
                          resultData.length > 0 &&
                          resultData.map((order, index) => {
                            return (
                              <Table.Row key={'user_' + index}>
                                <Table.Cell>{order.ORDER_ID}</Table.Cell>
                                <Table.Cell textAlign="left">
                                  {order.NAME}
                                </Table.Cell>
                                <Table.Cell singleLine>
                                  {order.START_DATE &&
                                    moment(order.START_DATE).format(
                                      'YYYY-MM-DD'
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                  {order.END_DATE &&
                                    moment(order.END_DATE).format('YYYY-MM-DD')}
                                </Table.Cell>
                                <Table.Cell>
                                  {order.LOCK_DATE &&
                                    moment(order.LOCK_DATE).format(
                                      'YYYY-MM-DD'
                                    )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {order.STATUS}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        {(resultData === undefined ||
                          resultData === null ||
                          resultData.length === 0) && (
                          <Table.Row>
                            <Table.Cell colSpan="6">
                              주문 정보가 존재하지 않습니다.{' '}
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell colSpan="6" className="text-center">
                            {resultData && resultData.length > 0 && (
                              <Pagination
                                activePage={currentPage}
                                ellipsisItem={{
                                  content: <Icon name="ellipsis horizontal" />,
                                  icon: true,
                                }}
                                firstItem={{
                                  content: <Icon name="angle double left" />,
                                  icon: true,
                                }}
                                lastItem={{
                                  content: <Icon name="angle double right" />,
                                  icon: true,
                                }}
                                prevItem={{
                                  content: <Icon name="angle left" />,
                                  icon: true,
                                }}
                                nextItem={{
                                  content: <Icon name="angle right" />,
                                  icon: true,
                                }}
                                boundaryRange={0}
                                siblingRange={2}
                                pointing
                                secondary
                                onPageChange={this.paginationChange}
                                totalPages={totalPages}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column textAlign="center">
                    <Button size="huge" onClick={this.handleOnClickGoBack}>
                      이전
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(MgrUserDetailOrder);
