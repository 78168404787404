import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { List, Card } from 'semantic-ui-react';

import queryString from 'query-string';

import * as actions from 'actions';

class Home extends Component {
  constructor(props) {
    super(props);

    const parsedSearch = queryString.parse(props.location.search);
    if (parsedSearch.recId !== undefined && parsedSearch.recId !== null) {
      //console.log(parsedSearch.recId);
      this.props.setRecommendUserInfo(parsedSearch.recId, 'KAKAO');
    }
  }

  render() {
    return (
      <Fragment>
        <div className="container my-5">
          <div className="row mb-4">
            <div className="col">
              <h4 className="mb-0">
                한국어 워드넷사전 (Korean Wordnet Dictionary)
              </h4>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col">
                  <h5>한국어 워드넷사전의 특징</h5>
                  <List ordered>
                    <List.Item as="span">
                      <span className="text-highlight">
                        “같은 뜻의 다른 단어는 없을까?”
                      </span>
                      라는 의문에 답하는 사전입니다.
                    </List.Item>
                    <List.Item as="span">
                      검색 결과는 비슷한말, 반대말, 높임말, 낮춤말 등{' '}
                      <span className="text-highlight">11개 카테고리</span>로
                      구분되어, 의미상 유사하거나 반대되거나 관련된 어휘들을
                      포괄적으로 제공합니다.
                    </List.Item>
                    <List.Item as="span">
                      국립국어원의 표준국어대사전 및 우리말샘사전에 수록된 최신
                      어휘까지 가능한 많은 유의어를 포함하고 있습니다.
                    </List.Item>
                    <List.Item as="span">
                      한글 유의어 사전 중 가장 방대한 규모로, 약 50만 개의
                      우리말 어휘에 대해{' '}
                      <span className="text-highlight">
                        151만 개 이상의 유의어 및 관련어
                      </span>
                      가 수록되어 있습니다.
                    </List.Item>
                    <List.Item as="span">
                      각 단어나 표현별 검색이 가능하며,{' '}
                      <span className="text-highlight">
                        텍스트 위주의 경량 사전
                      </span>
                      으로 스마트폰에서도 사용이 편리합니다.
                    </List.Item>
                    <List.Item as="span">
                      검색 결과는 카테고리별로 출력되며, 난이도가 쉬운 단어부터
                      어려운 단어 순으로 정렬되어{' '}
                      <span className="text-highlight">
                        적절한 유의어 선택에 도움
                      </span>
                      에 도움이 됩니다.
                    </List.Item>
                    <List.Item as="span">
                      표제어는{' '}
                      <span className="text-highlight">동형이의어를 기반</span>
                      으로 하며, 한자가 다른 경우 별도의 표제어로 등록되어
                      있습니다.
                    </List.Item>
                  </List>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col">
                  <Card fluid>
                    <Card.Content>
                      <table className="table">
                        <thead className="">
                          <tr className="">
                            <th className="text-center">Category</th>
                            <th className="text-center">개수</th>
                            <th className="text-center">비율</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <tr className="">
                            <td className="text-center">비슷한말</td>
                            <td className="text-right">889,135</td>
                            <td className="text-right">58.7%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">반대말</td>
                            <td className="text-right">37,597</td>
                            <td className="text-right">2.5%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">높임말</td>
                            <td className="text-right">831</td>
                            <td className="text-right">0.1%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">낮춤말</td>
                            <td className="text-right">164</td>
                            <td className="text-right">0.0%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">준말</td>
                            <td className="text-right">3,089</td>
                            <td className="text-right">0.2%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">본말</td>
                            <td className="text-right">1,974</td>
                            <td className="text-right">0.1%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">방언</td>
                            <td className="text-right">111,240</td>
                            <td className="text-right">7.3%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">상위어</td>
                            <td className="text-right">128,133</td>
                            <td className="text-right">8.5%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">하위어</td>
                            <td className="text-right">79,195</td>
                            <td className="text-right">5.2%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">참고 어휘</td>
                            <td className="text-right">81,133</td>
                            <td className="text-right">5.4%</td>
                          </tr>
                          <tr className="">
                            <td className="text-center">관련어</td>
                            <td className="text-right">181,834</td>
                            <td className="text-right">12.0%</td>
                          </tr>
                        </tbody>
                        <tfoot className="">
                          <tr className="">
                            <th className="text-center">총합계</th>
                            <th className="text-right">1,514,325</th>
                            <th className="text-right">100%</th>
                          </tr>
                        </tfoot>
                      </table>
                    </Card.Content>
                  </Card>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col">
                  <h5>[기 타]</h5>
                  <List ordered>
                    <List.Item as="span">
                      <a
                        href="https://natmal.com/"
                        target="_blank"
                        className="text-link"
                      >
                        낱말닷컴(natmal.com)
                      </a>
                      의 '낱말창고' 유의어 사전은{' '}
                      <span className="text-highlight">
                        어휘의 의미를 문형을 고려하여 27,000여 개로 범주화한
                        사전
                      </span>
                      입니다. 반면, 한국어 워드넷사전은 의미별 구분을 하지
                      않습니다.
                    </List.Item>
                    <List.Item as="span">
                      한국어 워드넷사전은 뜻풀이를 제공하지 않습니다. 뜻풀이가
                      필요하신 분은{' '}
                      <a
                        href="https://opendict.korean.go.kr/main"
                        target="_blank"
                        className="text-link"
                      >
                        국립국어원의 우리말샘사전(opendict.korean.go.kr)
                      </a>
                      을 이용해 주시기 바랍니다.
                    </List.Item>{' '}
                    {/*
                                        <List.Item as='span'>기본유의어사전은 데이터베이스 형태로 판매합니다. 구입할 의향이 있으시면 master@natmal.com 으로 문의 메일 주시기 바랍니다.
                                        </List.Item>
                                        */}
                    <List.Item as="span">
                      기타 문의사항은 master@natmal.com 으로 문의 메일 주시기
                      바랍니다.
                    </List.Item>
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-footer">
          <div className="footer-content">
            <div className="row">
              <div className="col">
                <div className="">
                  <Link to="/" className="cursor-pointer">
                    <img
                      src="/assets/images/logos/Logo_30_G.png"
                      alt=""
                      className="header-logo"
                    />
                  </Link>
                </div>
                <div className="mt-3">
                  <List>
                    <List.Item className="mb-1">
                      <List.Icon name="building" />
                      <List.Content>등록번호 220-87-16688</List.Content>
                    </List.Item>
                    <List.Item className="mb-1">
                      <List.Icon name="sticky note" />
                      <List.Content>
                        신고번호 제 2018-서울송파-0463
                      </List.Content>
                    </List.Item>
                    <List.Item className="mb-1">
                      <List.Icon name="phone" />
                      <List.Content>+82 2 3463 2321</List.Content>
                    </List.Item>
                    <List.Item className="mb-1">
                      <List.Icon name="mail" />
                      <List.Content>master@natmal.com</List.Content>
                    </List.Item>
                    <List.Item className="mb-1">
                      <List.Icon name="map pin" />
                      <List.Content>
                        서울특별시 송파구 법원로 114 엠스테이트 B동
                        1102호(문정동)
                      </List.Content>
                    </List.Item>
                  </List>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright mt-5">
            <div className="container text-center">
              © Copyright Natmal Corp. All rights reserved. 2020
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setRecommendUserInfo: actions.setRecommendUserInfo,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(Home));
