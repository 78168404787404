import axios from 'axios';
import 'react-app-polyfill/ie11';
import * as types from 'actions/ActionTypes';
class payService {
  payOrderCreate = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/pay/order/create',
          {
            data: datas,
          },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  payOrderComplete = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/pay/order/complete',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  payOrderFail = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/pay/order/fail',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  usedSuccess = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/pay/order/usedSuccess',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  cancelPay = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/pay/order/cancel',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
  isUsedService = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(types.LOCAL_API_URL + '/api/used/isUsed?id=' + id, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  isUsedByManagerService = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/used/isUsedByManager',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  isUsedRemainService = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(types.LOCAL_API_URL + '/api/used/isUsedRemain', {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  usedList = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/used/usedList',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  usedPaymentsList = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/used/paymentLists',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  usedOnOff = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/api/used/onOff',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  // billings = (datas) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         types.LOCAL_API_URL + '/billings',
  //         { data: datas },
  //         { withCredentials: true }
  //       )
  //       .then((response) => {
  //         if (response.data) {
  //           resolve(response.data);
  //         } else {
  //           reject(response.data);
  //         }
  //       });
  //   });
  // };
  // subscribe = (datas) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         types.LOCAL_API_URL + '/subscribe',
  //         { data: datas },
  //         { withCredentials: true }
  //       )
  //       .then((response) => {
  //         if (response.data) {
  //           resolve(response.data);
  //         } else {
  //           reject(response.data);
  //         }
  //       });
  //   });
  // };
  // subscribeCancel = (datas) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         types.LOCAL_API_URL + '/subscribe/cancel',
  //         { data: datas },
  //         { withCredentials: true }
  //       )
  //       .then((response) => {
  //         if (response.data) {
  //           resolve(response.data);
  //         } else {
  //           reject(response.data);
  //         }
  //       });
  //   });
  // };
  genNewMerchantUID = async () => {
    try {
      const result = await axios.get(
        types.LOCAL_API_URL + '/subscribe/generate-merchant-uid'
      );
      if (result.status === 200) {
        return result.data.merchant_uid;
      }
    } catch (e) {
      return e;
    }
  };
  wordnetBillings = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/wordnet-billings',
        { data: datas },
        { withCredentials: true }
      );
      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  userCancelPayment = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/user-cancel-payments',
        { data: datas },
        { withCredentials: true }
      );
      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  adminCancelSubscribePayment = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/admin-cancel',
        { data: datas },
        { withCredentials: true }
      );
      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };

  getSubscribeInfomation = (datas) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          types.LOCAL_API_URL + '/subscribe/info',
          { data: datas },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };
  userCancelSubscribe = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/user-cancel-subscribe',
        { data: datas },
        { withCredentials: true }
      );
      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  subscribePayCompleteForAdmin = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/admin-success',
        { data: datas },
        { withCredentials: true }
      );
      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  initializeDatabase = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/initializeDatabase',
        { data: datas },
        { withCredentials: true }
      );

      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  downgradeSubscribe = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/downgrade',
        { data: datas },
        { withCredentials: true }
      );

      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  getPayment = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/get-payment',
        { data: datas },
        { withCredentials: true }
      );

      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
  findSubscribePayment = async (datas) => {
    try {
      const result = await axios.post(
        types.LOCAL_API_URL + '/subscribe/payments/find-payment',
        { data: datas },
        { withCredentials: true }
      );

      if (result.status === 200) {
        return result;
      }
    } catch (e) {
      throw e;
    }
  };
}

const instance = new payService();
export default instance;
