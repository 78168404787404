import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Message, Divider } from 'semantic-ui-react';
import Toastr from 'modern-toastr';
import payService from 'services/payments';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class MgrPaymentDetail extends Component {
  constructor(props) {
    super(props);

    let orderUsedOpt = systemTypes.ORDER_USED_STATUS_LABEL.map((key) => {
      let item = systemTypes.ORDER_USED_STATUS.properties[key];
      return {
        key: item.id,
        text: item.label,
        value: item.value,
        style: { fontSize: '0.9em' },
      };
    });

    const state = {
      paymentItem: null,
      orderId: 0,
      orderCode: '',
      orderName: '',
      paymentStatus: '',
      orderStatus: '',
      orderUsedStatus: '',
      orderRefund: '',
      userEmail: '',
      userName: '',
      orderDate: '',
    };

    const paymentItem =
      props.location.post !== undefined && props.location.post !== null
        ? props.location.post.paymentItem
        : null;
    state.paymentItem = paymentItem;
    state.orderId =
      paymentItem && paymentItem.ORDER_ID !== null
        ? parseInt(paymentItem.ORDER_ID)
        : '';
    state.orderCode =
      paymentItem && paymentItem.MERCHANT_UID !== null
        ? paymentItem.MERCHANT_UID
        : '';
    state.orderName =
      paymentItem && paymentItem.NAME !== null ? paymentItem.NAME : '';
    state.paymentStatus =
      paymentItem && paymentItem.NAME !== null
        ? paymentItem.P_STATUS.trim()
        : '';
    state.orderStatus =
      paymentItem && paymentItem.NAME !== null
        ? paymentItem.O_STATUS.trim()
        : '';
    state.orderUsedStatus =
      paymentItem && paymentItem.NAME !== null
        ? paymentItem.U_STATUS.trim()
        : '';
    state.orderRefund =
      paymentItem && paymentItem.REFUND !== null
        ? paymentItem.REFUND.trim()
        : '';
    state.userEmail =
      paymentItem && paymentItem.USEREMAIL !== null
        ? paymentItem.USEREMAIL.trim()
        : '';
    state.userName =
      paymentItem && paymentItem.USERNAME !== null
        ? paymentItem.USERNAME.trim()
        : '';
    state.orderDate =
      paymentItem && paymentItem.CREATE_DATE !== null
        ? moment(paymentItem.CREATE_DATE).format('YYYY-MM-DD')
        : '';

    this.state = {
      ...state,
      orderUsedOpt,
    };
  }

  componentDidMount() {}

  handleOnClickGoBack = () => {
    this.props.history.goBack();
  };

  typeHandler = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleOnClickRefund = async (e) => {
    e.preventDefault();
    const user = this.props.user;

    const { orderCode, paymentItem } = this.state;

    if (user !== undefined && user !== null && user.grade === 0) {
      try {
        if (paymentItem.REFUND && paymentItem.REFUND.trim() === '환불완료') {
          throw new Error('이미 환불처리가 된 결제건입니다.');
        }

        // ITEM_ID를 받아와야함. 정기구독에서만 관련된 로직 처리를 위해서.
        const result = await payService.findSubscribePayment({
          merchant_uid: orderCode,
        });

        if (!result.data.MERCHANT_UID) {
          throw new Error('결제 정보를 찾을 수 없습니다.');
        }
        // 정기구독인 경우
        const itemId = result.data.ITEM_ID;
        const reason = '사용자 요청에 의한 환불처리';
        if (itemId === 6) {
          await payService.adminCancelSubscribePayment({
            merchant_uid: orderCode,
            customer_uid: ['wordnet', 6, user.id].join('_'),
            reason,
          });
          this.props.history.goBack();
          Toastr.info('정기 결제 취소와 환불이 완료되었습니다.');
        } else {
          service
            .cancelPay({ merchant_uid: orderCode, reason })
            .then((data) => {
              if (data.status === 'success') {
                Toastr.info('환불이 완료되었습니다.');
                this.props.history.goBack();
              } else {
                Toastr.error(data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              Toastr.error('오류 발생으로 인해 처리가 불가합니다.');
            });
        }
      } catch (err) {
        console.error(err);
        Toastr.error(err.message);
      }

      // await payService.adminCancelSubscribePayment({
      //   merchant_uid: paymentItem.MERCHANT_UID,
      //   customer_uid: ['wordnet', 6, user.id].join('_'),
      //   reason,
      // });
      // service
      //   .cancelPay({ merchant_uid: orderCode, reason })
      //   .then((data) => {
      //     if (data.status === 'success') {
      //       Toastr.info('환불이 완료되었습니다.');
      //       this.props.history.goBack();
      //     } else {
      //       Toastr.error(data.message);
      //     }
      //   })
      // .catch((e) => {
      //   console.log(e);
      //   Toastr.error('오류 발생으로 인해 처리가 불가합니다.');
      // });
    } else {
      Toastr.error('관리자 권한이 필요합니다.');
    }
  };

  fetchUpdatePaymentInfo = async (params) => {
    /*
        const paymentInfo = await service.updatePaymentItem(params);

        if(paymentInfo !== null) {
            if(paymentInfo.status === 200) {
                Toastr.info(paymentInfo.data.message)
            } else {
                Toastr.error(paymentInfo.data.message);
            }
        } else {
            Toastr.error('결제정보 변경중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        }
        */
  };

  render() {
    const {
      orderCode,
      orderName,
      paymentStatus,
      orderStatus,
      orderUsedStatus,
      orderRefund,
      userEmail,
      userName,
      orderDate,
    } = this.state;

    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col">
            <Button
              size="tiny"
              circular
              icon="arrow left"
              onClick={this.handleOnClickGoBack}
            />
            <span className="ml-3 mt-1 text-13 font-600">회원 상세정보</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mx-auto">
            <Form>
              <Grid>
                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column>
                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="주문코드"
                          fluid
                          name="orderCode"
                          value={orderCode}
                          readOnly
                        />
                      </Form.Field>

                      <Form.Field width={8}>
                        <Form.Input
                          label="상품명"
                          fluid
                          name="orderName"
                          value={orderName}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="결제상태"
                          fluid
                          name="paymentStatus"
                          value={paymentStatus}
                          readOnly
                        />
                      </Form.Field>

                      <Form.Field width={8}>
                        <Form.Input
                          label="주문상태"
                          fluid
                          name="orderStatus"
                          value={orderStatus}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="사용상태"
                          fluid
                          name="orderUsedStatus"
                          value={orderUsedStatus}
                          readOnly
                        />
                      </Form.Field>

                      <Form.Field width={8}>
                        <Form.Input
                          label="환불"
                          fluid
                          name="orderRefund"
                          value={orderRefund}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="사용자 이메일"
                          fluid
                          name="userEmail"
                          value={userEmail}
                          readOnly
                        />
                      </Form.Field>

                      <Form.Field width={8}>
                        <Form.Input
                          label="사용자명"
                          fluid
                          name="userName"
                          value={userName}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Form.Group width={16}>
                      <Form.Field width={8}>
                        <Form.Input
                          label="결제일"
                          fluid
                          name="orderDate"
                          value={orderDate}
                          readOnly
                        />
                      </Form.Field>
                    </Form.Group>

                    <Divider />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column textAlign="center">
                    <Button size="huge" onClick={this.handleOnClickGoBack}>
                      이전
                    </Button>
                    <Button
                      size="huge"
                      color="teal"
                      onClick={this.handleOnClickRefund}
                    >
                      환불하기
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(MgrPaymentDetail);
