import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Grid, Segment, Form, Input, Button, Modal, Icon, Header, Divider } from 'semantic-ui-react'
import Calendar from 'react-calendar';
import MgrStatisticUser from './mgrStatisticUser';
import MgrStatisticUserConnect from './mgrStatisticUserConnect';
import MgrStatisticSearchCount from './mgrStatisticSearchCount';
import MgrStatisticSearchWord  from './mgrStatisticSearchWord';

const moment = require('moment');

class UserStatistic extends Component {    

    constructor(props) {
        super(props);

        this.state = {
            startDate: moment().add(-5, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            openStartCalendar: false,
            openEndCalendar: false,
        }
    }

    handleClickOpenStartCalendar = () => {
		this.setState({
            openStartCalendar: true,
		});
	};

    handleClickCloseStartCalendar = () => {
		this.setState({
			openStartCalendar: false,
		});
    };

    handleClickOpenEndCalendar = () => {
		this.setState({
            openEndCalendar: true,
		});
	};

    handleClickCloseEndCalendar = () => {
		this.setState({
			openEndCalendar: false,
		});
    };

    handleOnChangeStartDate = (date) => {
        this.setState({
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }

    handleOnChangeEndDate = (date) => {
        this.setState({
            endDate: moment(date).format('YYYY-MM-DD')
        });
    }

    render() {
        const { startDate, endDate, openStartCalendar, openEndCalendar } = this.state;
        const panes = [
            { menuItem: '회원가입', render: () => 
                <Tab.Pane>
                    <MgrStatisticUser startDate={startDate} endDate={endDate} />                                    
                </Tab.Pane>
            },           
            { menuItem: '접속자', render: () => 
                <Tab.Pane>
                    <MgrStatisticUserConnect startDate={startDate} endDate={endDate} />                    
                </Tab.Pane> 
            },
            { menuItem: '사전검색', render: () => 
                <Tab.Pane>
                    <MgrStatisticSearchCount startDate={startDate} endDate={endDate} />                    
                </Tab.Pane> 
            },
            { menuItem: '상위검색어', render: () => 
                <Tab.Pane>
                    <MgrStatisticSearchWord topCount={10} />                    
                </Tab.Pane> 
            },
        ];     
        
        const startTime = new Date();
        const endTime = new Date();

        return (
          <div className="container my-5">
            <div className="row mb-4">
              <div className="col text-uppercase">
                <h4 className="mb-0 main-title">사용자 통계</h4>
              </div>
              <div className="col-auto align-self-end"></div>
            </div>

            <div className="row mt-4">
              <div className="col-12 mb-4">
                <Form>
                  <Grid>
                    <Grid.Row style={{ marginLeft: '0px' }}>
                      <Grid.Column>
                        <Segment color="black" clearing>
                          <Form.Field inline>
                            <Form.Group widths={4} style={{ margin: 'auto' }}>
                              <Form.Field width={3}>
                                <Input
                                  name="startDate"
                                  fluid
                                  icon="calendar alternate"
                                  iconPosition="left"
                                  placeholder="시작일"
                                  value={startDate}
                                  onKeyPress={this.searchOnKeyPress}
                                  onChange={this.onInputTextChange}
                                  onClick={this.handleClickOpenStartCalendar}
                                />
                              </Form.Field>
                              <Form.Field width={4}>
                                <Input
                                  name="endDate"
                                  fluid
                                  icon="calendar alternate"
                                  iconPosition="left"
                                  placeholder="종료일"
                                  value={endDate}
                                  onKeyPress={this.searchOnKeyPress}
                                  onChange={this.onInputTextChange}
                                  onClick={this.handleClickOpenEndCalendar}
                                  action={{
                                    size: 'tiny',
                                    color: 'grey',
                                    children: '검색',
                                    onClick:
                                      this.handleClickSearchUserStatistic,
                                  }}
                                />
                              </Form.Field>
                              <Form.Field width={9}>
                                <Button.Group basic>
                                  <Button>일</Button>
                                  <Button>주</Button>
                                  <Button>월</Button>
                                </Button.Group>
                              </Form.Field>
                            </Form.Group>
                          </Form.Field>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </div>
            <Tab panes={panes} />
            <Modal
              size="small"
              open={openStartCalendar}
              onClose={this.handleClickCloseStartCalendar}
              className="custom-modal"
            >
              <div className="pt-3 pl-3 text-13 font-600">
                <Icon name="calendar alternate outline" />
                <span>시작일</span>
              </div>
              <Divider />
              <div className="pl-3">
                <p>
                  <Calendar
                    onChange={this.handleOnChangeStartDate}
                    value={startTime}
                  />
                </p>
              </div>
              <Divider />
              <div className="text-center mb-3">
                <Button
                  color="red"
                  onClick={this.handleClickCloseStartCalendar}
                >
                  <Icon name="remove" /> 닫기
                </Button>
              </div>
            </Modal>

            <Modal
              size="small"
              open={openEndCalendar}
              onClose={this.handleClickCloseEndCalendar}
              className="custom-modal"
            >
              <div className="pt-3 pl-3 text-13 font-600">
                <Icon name="calendar alternate outline" />
                <span>종료일</span>
              </div>
              <Divider />
              <div className="pl-3">
                <p>
                  <Calendar
                    onChange={this.handleOnChangeEndDate}
                    value={endTime}
                  />
                </p>
              </div>
              <Divider />
              <div className="text-center mb-3">
                <Button color="red" onClick={this.handleClickCloseEndCalendar}>
                  <Icon name="remove" /> 닫기
                </Button>
              </div>
            </Modal>
          </div>
        );
    }
}

export default withRouter(UserStatistic);