import React, { useState, Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Table,
  Responsive,
  Pagination,
  Icon,
  Button,
} from 'semantic-ui-react';
import { Button as AntdButton, Form, Input, Modal, Radio } from 'antd';

import Toastr from 'modern-toastr';
import payService from 'services/payments';

import * as moment from 'moment';
import './subscribe-cancel.css';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const ModalComponent = ({
  usedItem,
  setSubscribeCancel,
  fetchCurrentUsedItem,
  fetchSelectUsedItems,
  pageSize,
  pageNum,
}) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const [textAreaValue, setTextAreaValue] = useState();
  const [cancelReasonObj, setCancelReasonObj] = useState();

  // const [formValues, setFormValues] = useState();
  // const onCreate = async (values) => {
  //   setFormValues(values);
  // };

  async function cancelSubscribe() {
    try {
      const result = await payService.userCancelSubscribe({
        customer_uid: ['wordnet', usedItem.ITEM_ID, usedItem.USER_ID].join('_'),
        reason: textAreaValue ? textAreaValue : '사용자 요청에 의한 취소',
      });
      if (result.status === 200 && result.data.code === 'success') {
        setSubscribeCancel();
        fetchCurrentUsedItem(usedItem.USER_ID);
        fetchSelectUsedItems({
          userId: usedItem.USER_ID,
          pageSize: pageSize,
          pageNum: 1,
        });
        alert(result.data.message);
        setOpen(false);
      } else if (result.status === 200 && result.data.code === 'fail') {
        fetchCurrentUsedItem(usedItem.USER_ID);
        fetchSelectUsedItems({
          userId: usedItem.USER_ID,
          pageSize: pageSize,
          pageNum: 1,
        });
        alert(result.data.message);
        setOpen(false);
      }
    } catch (e) {
      console.log('🚀 ~ cancelSubscribe ~ e:', e);

      alert(
        '구독 취소 처리 과정에서 문제가 발생했습니다. 잠시 후 다시 시도해 주세요. 문제가 계속될 경우 고객센터로 문의해 주세요.'
      );
    }
  }

  useEffect(() => {
    setCancelReasonObj({
      cancel_reason: textAreaValue,
    });
  }, [textAreaValue]);

  // useEffect(() => {
  //   async function cancelSubscribe() {
  //     try {
  //       const result = await payService.userCancelSubscribe({
  //         customer_uid: ['wordnet', usedItem.ITEM_ID, usedItem.USER_ID].join(
  //           '_'
  //         ),
  //         reason: formValues?.cancel_reason
  //           ? formValues?.cancel_reason
  //           : '사용자 요청에 의한 취소',
  //       });
  //       if (result.status === 200 && result.data.code === 'success') {
  //         setSubscribeCancel();
  //         fetchCurrentUsedItem(usedItem.USER_ID);
  //         fetchSelectUsedItems({
  //           userId: usedItem.USER_ID,
  //           pageSize: pageSize,
  //           pageNum: 1,
  //         });
  //         alert(result.data.message);
  //         setOpen(false);
  //       } else if (result.status === 200 && result.data.code === 'fail') {
  //         fetchCurrentUsedItem(usedItem.USER_ID);
  //         fetchSelectUsedItems({
  //           userId: usedItem.USER_ID,
  //           pageSize: pageSize,
  //           pageNum: 1,
  //         });
  //         alert(result.data.message);
  //         setOpen(false);
  //       }
  //     } catch (e) {
  //       console.log('🚀 ~ cancelSubscribe ~ e:', e);

  //       alert(
  //         '구독 취소 처리 과정에서 문제가 발생했습니다. 잠시 후 다시 시도해 주세요. 문제가 계속될 경우 고객센터로 문의해 주세요.'
  //       );
  //     }
  //   }
  //   if (formValues?.cancel_reason) {
  //     cancelSubscribe();
  //   }
  // }, [formValues]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>구독 취소</Button>

      <Modal
        open={open}
        okText="구독 취소"
        cancelText="취소"
        title={
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
            }}
          >
            구독 취소 안내
          </span>
        }
        footer={
          <>
            <AntdButton onClick={() => setOpen(false)}>취소</AntdButton>
            <AntdButton
              onClick={async () => await cancelSubscribe()}
              style={{
                backgroundColor: '#ff4d4f',
                borderColor: '#ff4d4f',
                color: 'white',
              }}
            >
              구독 취소 확인
            </AntdButton>
          </>
        }
        okButtonProps={{
          autoFocus: true,
          htmlType: 'submit',
        }}
        onCancel={() => setOpen(false)}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            initialValues={{
              modifier: 'public',
            }}
            clearOnDestroy
            // onFinish={(values) => onCreate(values)}
          >
            {dom}
          </Form>
        )}
      >
        <Form.Item>
          <div
            style={{
              backgroundColor: 'rgb(222 230 237)',
              padding: '12px',
              borderRadius: '5px',
            }}
          >
            <p className="font-bold">
              구독 취소 시 아래 사항을 참고해 주시기 바랍니다.
            </p>

            <p>
              • 현재 구독하신 서비스는{' '}
              <span className="font-bold" style={{ color: '#ff4d4f' }}>
                {moment(usedItem.END_DATE).format('YYYY년 MM월 DD일')}
              </span>
              까지 계속 이용하실 수 있습니다.
            </p>
            <p>• 남은 기간 동안은 모든 서비스 이용이 가능합니다.</p>
            <p>• 구독 취소 후에는 자동 결제가 중단됩니다.</p>
            <p>
              • 언제든 다시 구독을 원하시면 서비스 기간 만료 후 재구독이
              가능합니다.
            </p>
          </div>
        </Form.Item>
        <Form.Item
          label="더 나은 서비스를 제공하기 위해, 구독을 취소하신 이유를 알려주시면 감사하겠습니다. (선택 사항)"
          name="cancel_reason"
        >
          <Input.TextArea
            allowClear
            showCount
            style={{
              marginTop: '20px',
              marginBottom: '10px',
            }}
            onChange={(e) => setTextAreaValue(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </>
  );
};

class UsedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedItem: null,
      usedItems: null,
      pageSize: 10,
      totalPages: 0,
      currentPage: 1,
      subscribeStatus: false,
      open: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.user });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user != this.state.user) {
      const user = this.state.user;
      this.fetchCurrentUsedItem(user.id);
      this.fetchSelectUsedItems({
        userId: user.id,
        pageSize: this.state.pageSize,
        pageNum: this.state.currentPage,
      });
    }
  }

  componentDidMount() {
    const user = this.props.user;
    if (user) {
      this.setState({ user: this.props.user });
    }
  }

  paginationChange = (activePage) => {
    //const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
    const user = this.props.user;
    if (user) {
      this.setState({ activePage: activePage, selectedIndex: 0 });
      this.fetchListAll(
        Object.assign({}, this.state, { offset: activePage, user })
      );
    }
  };

  fetchCurrentUsedItem = (id) => {
    let used;
    payService
      .isUsedService(id)
      .then((data) => {
        //console.log(data.used);
        if (data.status) {
          used = data.used;
          this.setState({ usedItem: data.used });
        }
      })
      .then(() => {
        if (used) {
          payService
            .getSubscribeInfomation({
              customer_uid: ['wordnet', used.ITEM_ID, used.USER_ID].join('_'),
              item_id: used.ITEM_ID,
              user_id: used.USER_ID,
            })
            .then((res) => {
              if (res.code === 'success' && res.data.length > 0) {
                this.setState({
                  subscribeStatus: true,
                });
              }
            });
        }
      });
  };

  fetchSelectUsedItems = (params) => {
    payService.usedList(params).then((data) => {
      //console.log(data);
      if (data) {
        let totalPages = Math.ceil(parseInt(data.total) / this.state.pageSize);
        this.setState({ totalPages: totalPages, usedItems: data.datas });
      }
    });
  };

  setSubscribeCancel = () => {
    this.setState({ subscribeStatus: false });
  };

  render() {
    const { usedItem, usedItems, currentPage, totalPages } = this.state;

    return (
      <div className="container">
        <div className="row mb-4">
          <div className="col">
            <h4 className="mb-0 main-title">사용 내역</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <div className="ui message box-transparent">
                  {usedItem && (
                    <ul
                      className="list"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        listStyleType: 'none',
                      }}
                    >
                      <span
                        className="content"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        현재&nbsp;{' '}
                        <span className="font-700">
                          「{usedItem.WordnetItem.NAME}」
                        </span>{' '}
                        을 사용하고 있습니다.
                      </span>
                      {usedItem.ITEM_ID === 6 && this.state.subscribeStatus ? (
                        <ModalComponent
                          usedItem={usedItem}
                          setSubscribeCancel={this.setSubscribeCancel}
                          fetchCurrentUsedItem={this.fetchCurrentUsedItem}
                          fetchSelectUsedItems={this.fetchSelectUsedItems}
                          pageSize={this.state.pageSize}
                          pageNum={this.state.currentPage}
                        />
                      ) : (
                        <li className="content">
                          서비스 기간:{' '}
                          <span className="font-600">
                            {moment(usedItem.START_DATE).format('YYYY-MM-DD')} ~{' '}
                            {moment(usedItem.END_DATE).format('YYYY-MM-DD')}
                          </span>
                        </li>
                      )}

                      {usedItem.ITEM_ID !== 6 && (
                        <li className="content">
                          서비스 기간:{' '}
                          <span className="font-600">
                            {moment(usedItem.START_DATE).format('YYYY-MM-DD')} ~{' '}
                            {moment(usedItem.END_DATE).format('YYYY-MM-DD')}
                          </span>
                        </li>
                      )}
                    </ul>
                  )}
                  {!usedItem && (
                    <ul
                      className="list"
                      style={{
                        listStyleType: 'none',
                      }}
                    >
                      <li className="content">
                        현재 사용 중인 구독권이 없습니다.
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-4">
            <Grid>
              <Grid.Row style={{ marginLeft: '0px' }}>
                <Grid.Column>
                  <Table
                    key="black"
                    fixed
                    selectable
                    textAlign="center"
                    style={{
                      position: 'relative',
                      padding: '0px',
                      margin: '0px',
                    }}
                  >
                    <Responsive as={Table.Header} minWidth={768}>
                      <Table.Row>
                        <Table.HeaderCell textAlign="center" width="2">
                          상태
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width="2">
                          종류
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width="4">
                          사용명
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width="2">
                          남은 일수
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" width="2">
                          사용일
                        </Table.HeaderCell>
                      </Table.Row>
                    </Responsive>
                    <Table.Body>
                      {usedItems &&
                        usedItems.map((item, key) => {
                          const t = new Date();
                          let remain = 0;

                          if (item.STATUS.trim() === 'LOCK') {
                            const theDate = new Date(item.LOCK_DATE);
                            const diffDate = t - theDate;
                            const lockRemain = Math.ceil(
                              diffDate / (60 * 1000 * 60 * 24)
                            );
                            const date = new Date(item.END_DATE);
                            date.setDate(date.getDate() + lockRemain);
                            const enddate = date;

                            const diffDate2 = enddate - t;
                            remain = Math.ceil(
                              diffDate2 / (60 * 1000 * 60 * 24)
                            );
                          } else {
                            const enddate = new Date(item.END_DATE);
                            const diffDate2 = enddate - t;
                            remain = Math.ceil(
                              diffDate2 / (60 * 1000 * 60 * 24)
                            );
                          }

                          return (
                            <Table.Row
                              key={'use_' + key}
                              className="cursor-pointer"
                            >
                              <Table.Cell textAlign="center">
                                {item.STATUS.trim() === 'START' && (
                                  <span>사용중</span>
                                )}
                                {item.STATUS.trim() === 'LOCK' && (
                                  <span>일시중지</span>
                                )}
                                {item.STATUS.trim() === 'EXPIRED' && (
                                  <span>사용만료</span>
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {item.WordnetOrder.MERCHANT_UID !== undefined &&
                                  item.WordnetOrder.MERCHANT_UID !== null && (
                                    <span>이용권</span>
                                  )}
                                {(item.WordnetOrder.MERCHANT_UID ===
                                  undefined ||
                                  item.WordnetOrder.MERCHANT_UID === null) && (
                                  <span>쿠폰</span>
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {item.WordnetItem.NAME}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {item.STATUS.trim() === 'EXPIRED'
                                  ? `${remain} 일`
                                  : this.state.subscribeStatus
                                  ? '구독중'
                                  : `${remain} 일`}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {moment(item.UPDATE_DATE).format('YYYY-MM-DD')}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      {(usedItems === undefined ||
                        usedItems === null ||
                        usedItems.length === 0) && (
                        <Table.Row>
                          <Table.Cell colSpan="5">
                            사용내역이 존재하지 않습니다.{' '}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      <Table.Row>
                        <Table.Cell colSpan="5" className="text-center">
                          {usedItems && usedItems.length > 0 && (
                            <Pagination
                              activePage={currentPage}
                              ellipsisItem={{
                                content: <Icon name="ellipsis horizontal" />,
                                icon: true,
                              }}
                              firstItem={{
                                content: <Icon name="angle double left" />,
                                icon: true,
                              }}
                              lastItem={{
                                content: <Icon name="angle double right" />,
                                icon: true,
                              }}
                              prevItem={{
                                content: <Icon name="angle left" />,
                                icon: true,
                              }}
                              nextItem={{
                                content: <Icon name="angle right" />,
                                icon: true,
                              }}
                              boundaryRange={0}
                              siblingRange={2}
                              pointing
                              secondary
                              onPageChange={this.paginationChange}
                              totalPages={totalPages}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter(UsedList));
