import React, { Component } from 'react';
import { Card, List, Divider, Label } from 'semantic-ui-react';

class CompareSyn extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <div className="container my-5">
            <div className="row mb-4">
              <div className="col text-uppercase">
                <h4 className="mb-0 main-title">유의어 사전 비교</h4>
              </div>
              <div className="col-auto align-self-end"></div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <List bulleted>
                  <List.Item as="span">
                    낱말닷컴 유의어사전 (이하 낱말닷컴)
                  </List.Item>
                  <List.Item as="span">한국어 워드넷사전</List.Item>
                </List>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col">
                    <Divider horizontal>
                      <Label className="text-11">주요 특징 비교</Label>
                    </Divider>
                    <Card fluid>
                      <Card.Content>
                        <table className="table">
                          <thead className="">
                            <tr className="table-header">
                              <th className="text-center">낱말닷컴</th>
                              <th className="text-center">한국어 워드넷사전</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            <tr className="">
                              <td className="">23만 표제어와 63만 유의어</td>
                              <td className="">50만 표제어와 150만 유의어</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="">
                                의미별 구분을 통한 정교한 유의어 분류로 명확한
                                글쓰기에 도움
                              </td>
                              <td className="">
                                최신 어휘를 반영한 대규모의 유의어, 관련어를
                                제공하여 어휘력 향상에 도움
                              </td>
                            </tr>
                            <tr className="">
                              <td className="">
                                뜻풀이, 방사형 보기, 영어, 퍼즐 등 다양한 컨텐츠
                                제공
                              </td>
                              <td className="">
                                텍스트 위주의 단순한 형태로 스마트폰에서도 사용
                                가능
                              </td>
                            </tr>
                            <tr className="">
                              <td className="">비슷한말, 반대말 위주</td>
                              <td className="">
                                비슷한말, 반대말에 더하여 상위어, 하위어, 각종
                                관련어를 포함
                              </td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="">Natmal.com에서만 서비스 중</td>
                              <td className=""> - </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col">
                    <Divider horizontal>
                      <Label className="text-11">세부 특징 비교</Label>
                    </Divider>
                    <Card fluid>
                      <Card.Content>
                        <table className="table">
                          <thead className="">
                            <tr className="table-header">
                              <th className="text-center">항목</th>
                              <th className="text-center">낱말닷컴</th>
                              <th className="text-center">한국어 워드넷사전</th>
                              <th className="text-center">비고</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            <tr className="">
                              <td className="text-center">표제어 수</td>
                              <td className="text-center">230,536</td>
                              <td className="text-center">506,082</td>
                              <td className="text-center" rowSpan="2">
                                최신 표제어 반영(기본)
                              </td>
                            </tr>
                            <tr className="">
                              <td className="text-center">유의어 수</td>
                              <td className="text-center">632,589</td>
                              <td className="text-center">1,514,325</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">의미별 구분</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">뜻풀이</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center">
                                우리말샘 링크만 제공(기본)
                              </td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">최신 어휘</td>
                              <td className="text-center">x</td>
                              <td className="text-center">o</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">카테고리 수</td>
                              <td className="text-center">8</td>
                              <td className="text-center">11</td>
                              <td className="text-center">
                                상위어,하위어,관련어 추가(기본)
                              </td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">표제어 정의</td>
                              <td className="text-center">
                                표준국어대사전의 표제어 기준
                              </td>
                              <td className="text-center">동형이의어 기준</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">2차 유의어</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">방사형 보기</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">클라우드형 보기</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">난이도 표시</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center">
                                난이도는 어휘 배열에만 사용(기본)
                              </td>
                            </tr>
                            <tr className="">
                              <td className="text-center">단어장</td>
                              <td className="text-center">o</td>
                              <td className="text-center">o</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">영어</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">다국어</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center">
                                10개 언어 연결(낱말닷컴)
                              </td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">역순사전</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">의성.의태어</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">독서</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">퍼즐</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">한자어휘집</td>
                              <td className="text-center">o</td>
                              <td className="text-center">x</td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="">
                              <td className="text-center">표준국어</td>
                              <td className="text-center">표준국어사전 포함</td>
                              <td className="text-center">
                                우리말샘 링크만 제공
                              </td>
                              <td className="text-center"></td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">DB 판매</td>
                              <td className="text-center">x</td>
                              <td className="text-center">o</td>
                              <td className="text-center">
                                엑셀/텍스트/API 형태 제공(기본)
                              </td>
                            </tr>
                            <tr className="">
                              <td className="text-center">가격</td>
                              <td className="text-center">34,800원/년</td>
                              <td className="text-center">21,600원/년</td>
                              <td className="text-center">
                                기간별 이용권 구매
                              </td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">스마트폰사용</td>
                              <td className="text-center">x</td>
                              <td className="text-center">o</td>
                              <td className="text-center">
                                스마트폰 Web 사용(기본)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col">
                    <Divider horizontal>
                      <Label className="text-11">표제어 비교</Label>
                    </Divider>
                    <Card fluid>
                      <Card.Content>
                        <table className="table">
                          <thead className="">
                            <tr className="table-header">
                              <th className="text-center">단어</th>
                              <th className="text-center">낱말닷컴</th>
                              <th className="text-center">한국어 워드넷사전</th>
                              <th className="text-center">뜻풀이(우리말샘)</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            <tr className="">
                              <td rowSpan="5" className="text-center">
                                이상
                              </td>
                              <td className="text-center">이상01(二上)</td>
                              <td className="text-center">이상(二上)</td>
                              <td className="">시문을 평하는 …</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">이상02(二相)</td>
                              <td className="text-center" rowSpan="2">
                                이상(二相)
                              </td>
                              <td className="">장상과 공상을 …</td>
                            </tr>
                            <tr className="">
                              <td className="text-center">이상03(二相)</td>
                              <td className="">'우찬성'을 이르던 말</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">이상04(以上)</td>
                              <td className="text-center">이상(以上)</td>
                              <td className="">일정한 기준보다 …</td>
                            </tr>
                            <tr className="">
                              <td className="text-center">이상05(李箱)</td>
                              <td className="text-center">이상(李箱)</td>
                              <td className="">시인, 소설가</td>
                            </tr>
                            <tr className="">
                              <td rowSpan="6" className="text-center">
                                절
                              </td>
                              <td className="text-center">절01</td>
                              <td rowSpan="5" className="text-center">
                                절
                              </td>
                              <td className="">승려가 불상을 …</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">절02</td>
                              <td className="">남에게 공경의 …</td>
                            </tr>
                            <tr className="">
                              <td className="text-center">절03</td>
                              <td className="">'저를'의 줄임말</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">
                                *우리말샘에만 있는 표제어
                              </td>
                              <td className="">'저울'의 방언</td>
                            </tr>
                            <tr className="">
                              <td className="text-center">
                                *우리말샘에만 있는 표제어
                              </td>
                              <td className="">'물결'의 방언</td>
                            </tr>
                            <tr className="table-tr-light">
                              <td className="text-center">절06(節)</td>
                              <td className="text-center">절(節)</td>
                              <td className="">글의 내용을 …</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col">
                    <Divider horizontal>
                      <Label className="text-11">검색결과 비교</Label>
                    </Divider>
                    <Card fluid>
                      <Card.Content>
                        <table className="table">
                          <thead className="">
                            <tr className="table-header">
                              <th className="text-center">단어</th>
                              <th className="text-center" colSpan="3">
                                낱말닷컴
                              </th>
                              <th className="text-center" colSpan="2">
                                한국어 워드넷사전
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            <tr className="">
                              <td rowSpan="12" className="text-center">
                                떨어지다
                              </td>
                              <td rowSpan="5" className="text-center">
                                유의어
                              </td>
                              <td className="">위에서 아래로</td>
                              <td className="">흐르다 외 6개</td>
                              <td rowSpan="5" className="text-center">
                                비슷한말
                              </td>
                              <td rowSpan="5" className="">
                                갈라지다 외 88개
                              </td>
                            </tr>
                            <tr className="">
                              <td className="">잠/처지에</td>
                              <td className="">빠지다 외 2개</td>
                            </tr>
                            <tr className="">
                              <td className="">진지/성/요새가</td>
                              <td className="">넘어가다 외 1개</td>
                            </tr>
                            <tr className="">
                              <td className="">정나미/입맛/감기가</td>
                              <td className="">달아나다 외 3개</td>
                            </tr>
                            <tr className="">
                              <td className="">기타 13개 의미</td>
                              <td className=""></td>
                            </tr>
                            <tr className="">
                              <td rowSpan="3" className="text-center">
                                반의어
                              </td>
                              <td className="">시험/선거/선발에</td>
                              <td className="">합격하다 외 1개</td>
                              <td rowSpan="3" className="text-center">
                                반대말
                              </td>
                              <td className="">결합하다(結合하다) 외 8개</td>
                            </tr>
                            <tr className="">
                              <td className="">단추/벽지가</td>
                              <td className="">붙다 외 2개</td>
                              <td className=""></td>
                            </tr>
                            <tr className="">
                              <td className="">기타 3개 의미</td>
                              <td className=""></td>
                              <td className=""></td>
                            </tr>
                            <tr className="">
                              <td rowSpan="3" className="text-center">
                                방언
                              </td>
                              <td className="">강원</td>
                              <td className="">떠러트린다 외 2개</td>
                              <td rowSpan="3" className="text-center">
                                방언
                              </td>
                              <td className="">날치다(전라) 외 18개</td>
                            </tr>
                            <tr className="">
                              <td className="">경남</td>
                              <td className="">띤다 외 2개</td>
                              <td className=""></td>
                            </tr>
                            <tr className="">
                              <td className="">기타 5개 지역</td>
                              <td className=""></td>
                              <td className=""></td>
                            </tr>
                            <tr className="">
                              <td className=""></td>
                              <td className=""></td>
                              <td className=""></td>
                              <td className="text-center">관련어</td>
                              <td className="">동아따다</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default CompareSyn;