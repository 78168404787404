import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Form, Checkbox } from 'semantic-ui-react';
import Validator, { Validation } from 'es-validator';
import Toastr from 'modern-toastr';

import * as service from 'services/order';
import * as message from 'utils/message';

import payService from 'services/payments';
import queryString from 'query-string';
import {
  CHANNEL_TEST_KAKAOPAY,
  CHANNEL_TEST_KCP,
  CHANNEL_PROD_KAKAOPAY,
  CHANNEL_PROD_KCP,
  KCP_BILLING,
  KAKAOPAY,
  METHOD_CARD,
  METHOD_KAKAOPAY,
  TEST_KAKAOPAY,
  TEST_KCP_BILLING,
} from './const';
import { Link } from 'react-router-dom';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
  user: state.auth.user
});

const { REACT_APP_NODE_ENV } = process.env;

class OrderItems extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    if (query.status === 'fail') {
      alert('정기 결제를 사용할 수 없습니다. 다른 카드를 이용해 주세요.');
    }
    this.errorPrint = v => (
      <p key={v.key} className="help-block">
        {v.message}
      </p>
    );

    //유효성 검증
    this.validator = new Validator({
      isCheck: {
        _name: '동의',
        _hasText: { message: '주문 동의를 체크해 주세요' },
        _checked: {}
      },
      methods: {
        _name: '결제수단',
        _hasText: { message: '결제 수단을 선택해 주세요.' },
        _selected: {}
      }
    });

    this.state = {
      isCheck: false,
      methods: '',
      orderItems: [],
      orderPage: 1,
      selectedOrder: null
    };
  }

  componentDidMount() {
    this.fetchGetOrderItems();
  }

  handleOnClickShowPaymentType(item) {
    const user = this.props.user;
    if (user !== undefined && user !== null) {
      payService.isUsedService(user.id).then(data => {
        if (data.status) {
          Toastr.info(
            '현재 사용중인 이용권이 있습니다. \n사용중인 이용권의 이용기간이 끝난 후 결제가 가능합니다.'
          );
        } else {
          this.setState({
            orderPage: 2,
            selectedOrder: item
          });
        }
      });
    } else {
      Toastr.info(message.MSG_MEMBER_SERVICE);
    }
  }

  handleOnClickGoBack = e => {
    e.preventDefault();
    this.setState({
      orderPage: 1,
      selectedOrder: null,
      isCheck: false,
      methods: ''
    });
  };

  requestPay = vState => {
    return new Promise(resolve => {
      const IMP = window.IMP; // 생략해도 괜찮습니다.
      let localURL = window.location.protocol + '//' + window.location.host;
      let pg = '',
        pay_method = '',
        amount,
        m_redirect_url = localURL + '/pay/mobile';
      if (vState.methods === 'card' || vState.methods === 'phone') {
        IMP.init('imp24842693');
        pg = 'kcp';
        pay_method = vState.methods;
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'payco') {
        IMP.init('imp24842693');
        //pg = 'kcp.IP0AC';
        pg = 'kcp.A52CY';
        pay_method = 'payco';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'kakaopay') {
        IMP.init('imp24842693');
        pg = 'kakaopay';
        pay_method = 'card';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'paypal') {
        IMP.init('imp24842693');
        pg = 'paypal';
        pay_method = 'card';
        amount = Math.ceil(
          (vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID) / 1000
        );

        m_redirect_url = localURL + '/order';
      } else if (vState.methods === 'samsung') {
        IMP.init('imp24842693');
        pg = 'html5_inicis';
        pay_method = 'card';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      }

      const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
      if (user != null) {
        payService
          .payOrderCreate({
            id: vState.selectedOrder.ITEM_ID,
            title: vState.selectedOrder.NAME + '_wordnet',
            amount: amount,
            type: vState.selectedOrder.TYPE,
            pg,
            pay_method,
            user
          })
          .then(x => {
            // "imp00000000" imp24842693 대신 발급받은 "가맹점 식별코드"를 사용합니다.
            // IMP.request_pay(param, callback) 호출
            if (x) {
              if (vState.selectedOrder != null) {
                IMP.request_pay(
                  {
                    // param kcp: samsung, phone, card
                    pg,
                    pay_method,
                    merchant_uid: x.merchant_uid,
                    name: vState.selectedOrder.NAME + '_wordnet',
                    amount: amount,
                    buyer_email: user.userEmail,
                    buyer_name: user.userName,
                    buyer_tel: '',
                    buyer_addr: '',
                    buyer_postcode: '',
                    m_redirect_url
                  },
                  rsp => {
                    // callback
                    if (rsp.success) {
                      // 결제 성공 시 로직,
                      payService
                        .payOrderComplete({
                          imp_uid: rsp.imp_uid,
                          merchant_uid: rsp.merchant_uid
                        })
                        .then(data => {
                          // 가맹점 서버 결제 API 성공시 로직
                          //console.log(data);
                          switch (data.status) {
                            case 'success':
                              // 결제 성공 시 로직
                              payService
                                .usedSuccess({
                                  merchant_uid: rsp.merchant_uid,
                                  user
                                })
                                .then(result => {
                                  //console.log(result);
                                  if (result.status === 'redirect') {
                                    this.props.history.push({
                                      pathname: '/order'
                                    });
                                  }
                                });
                              break;
                            default:
                              break;
                          }
                        })
                        .catch(e => {
                          //결재 실패
                          console.log('fail', e);
                        });
                    } else {
                      // 결제 실패 시 로직,
                      console.log('fail', rsp);
                      this.setState({ error_msg: rsp.error_msg });

                      payService
                        .payOrderFail({
                          imp_uid: rsp.imp_uid,
                          merchant_uid: rsp.merchant_uid,
                          error_msg: rsp.error_msg
                        })
                        .then(data => {
                          switch (data.status) {
                            case 'success':
                              break;
                            default:
                              break;
                          }
                        })
                        .catch(e => {
                          console.log('fail', e);
                        });
                    }
                  }
                );
              } else {
                this.setState({
                  error_msg:
                    '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.'
                });
              }
            }

            Promise.resolve();
          })
          .catch(error => {
            Promise.resolve();
          });
      } else {
      }
      return Promise.resolve();
    });
  };

  requestSubcribe = async vState => {
    let localURL = window.location.protocol + '//' + window.location.host;

    const IMP = window.IMP; // 생략해도 괜찮습니다.
    const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
    if (!user || !vState.selectedOrder) {
      this.setState({
        error_msg: '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.'
      });
    }

    const isDevleopment = REACT_APP_NODE_ENV === 'development';
    const isCardMethod = vState.methods === 'card';
    const merchant_uid = await payService.genNewMerchantUID();
    const { selectedOrder } = vState;

    const itemData = {
      ITEM_ID: selectedOrder.ITEM_ID,
      NAME: selectedOrder.NAME,
      PRICE: selectedOrder.PRICE
    };
    const userData = {
      ID: user.id,
      USEREMAIL: user.userEmail,
      USERNAME: user.userName,
      PHONENUMBER: user.userPhone
    };

    const result = await payService.initializeDatabase({
      paymentData: {
        pg_provider: isDevleopment
          ? isCardMethod
            ? TEST_KCP_BILLING
            : TEST_KAKAOPAY
          : isCardMethod
          ? KCP_BILLING
          : KAKAOPAY,
        merchant_uid: merchant_uid,
        customer_uid: ['wordnet', selectedOrder.ITEM_ID, user.id].join('_'),
        pay_method: isCardMethod ? METHOD_CARD : METHOD_KAKAOPAY,
        paid_amount: selectedOrder.PRICE,
        paid_at: Math.floor(new Date().getTime() / 1000),
        name:
          selectedOrder.NAME + (isCardMethod ? '_빌링키_wordnet' : '_wordnet'),
        status: 'ready',
        create_date: new Date().toISOString(),
        update_date: new Date().toISOString()
      },
      item: itemData,
      user: userData
    });

    if (result.data.code !== 'success') {
      alert(`${result.data.message}`);
      return;
    }
    console.log(result.data.message);

    IMP.init('imp24842693');
    IMP.request_pay(
      {
        channelKey: isDevleopment
          ? isCardMethod
            ? CHANNEL_TEST_KCP
            : CHANNEL_TEST_KAKAOPAY
          : isCardMethod
          ? CHANNEL_PROD_KCP
          : CHANNEL_PROD_KAKAOPAY,
        pay_method: isCardMethod ? METHOD_CARD : METHOD_KAKAOPAY,
        name:
          vState.selectedOrder.NAME +
          (isCardMethod ? '_빌링키_wordnet' : '_wordnet'),
        amount: selectedOrder.PRICE,
        merchant_uid,
        customer_uid: ['wordnet', vState.selectedOrder.ITEM_ID, user.id].join(
          '_'
        ),
        buyer_email: user.userEmail,
        buyer_name: user.userName,
        buyer_tel: '',
        buyer_addr: '',
        buyer_postcode: '',
        m_redirect_url: localURL + '/subscribe/mobile-billings'
      },

      async rsp => {
        console.log('🚀 ~ OrderItems ~ rsp:', rsp);
        // 빌링키 발급 성공
        try {
          // 사용자가 결제 취소시
          if (!rsp.success) {
            await payService.userCancelPayment({ merchant_uid });
            throw new Error(rsp.error_msg);
          }

          if (rsp.status !== 'paid') {
            throw new Error(rsp.error_msg);
          }

          const { data } = await payService.getPayment(rsp);
          const result = await payService.wordnetBillings({
            paymentDataFromPG: data.payment,
          });

          if (result.status === 200 && result.data.code === 'success') {
            alert(result?.data?.message);

            this.props.history.push({
              pathname: '/order',
            });
          } else if (result.status === 200 && result.data.code === 'fail') {
            alert(result?.data?.message);

            this.props.history.push({
              pathname: '/payment',
            });
          } else throw new Error('');
        } catch (err) {
          if (err.message) alert(err.message);
          else alert('결제에 실패했습니다. 다른 카드를 이용해 주세요.');
          this.props.history.push({
            pathname: '/payment',
          });
        }
      }
    );
  };

  onChangeCheckedToArray = (e, { value }) => {
    this.setState({ methods: value });
  };

  onChangeCheckedToBoolean = (e, { checked }) => {
    this.setState({ isCheck: checked });
  };

  fetchGetOrderItems = async () => {
    let orderItems = await service.selectOrderItems();
    //console.log(orderItems);
    if (orderItems !== undefined && orderItems !== null) {
      if (orderItems.status === 200) {
        this.showOrderItems(orderItems.data);
      } else {
        this.setState({
          resultData: []
        });
      }
    } else {
      Toastr.error(message.MSG_HAPPEN_TO_LOAD_ERROR);
    }
  };

  showOrderItems(result) {
    const subscribeOrderItem = result.orderItems.filter(
      item => item.ITEM_ID === 6
    );
    this.setState({
      orderItems: subscribeOrderItem
    });
  }

  numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  onSubmit(e) {
    e.preventDefault();
    const { methods, isCheck, selectedOrder } = this.state;
    if (methods && isCheck) {
      if (selectedOrder.ITEM_ID === 6) {
        this.requestSubcribe(this.state);
      } else {
        this.requestPay(this.state);
      }
    } else {
      if (!methods) {
        Toastr.error('결제수단을 선택해 주세요.');
      }
      if (!isCheck) {
        Toastr.error('주문동의를 체크해 주세요.');
      }
    }
  }

  render() {
    const {
      orderItems,
      orderPage,
      selectedOrder,
      methods,
      isCheck
    } = this.state;
    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0 main-title">구독권 구매 안내</h4>
          </div>
          <div className="col-auto align-self-end"></div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <h4 className="font-400">
                  구매 전에 아래 내역을 꼭 확인해 주세요.
                </h4>
                {/* <div className="ui negative message">
                  <ul className="list">
                    <li className="content">
                      모든 구독권은 부가세(10%)가 포함되어 있습니다.
                    </li>
                    <li className="content">
                      결제 취소는 당일 결제 건만 가능합니다. <br />
                      (단, 사용이력이 없는 경우에 한함.)
                    </li>
                    <li className="content">
                      결제대행사의 사정으로 12개월 이상 이용권은 연간 구독으로
                      운영됩니다.
                    </li> 
                    <li>
                      유료 구독 상품은 부가가치세(10%)가 포함되어 있습니다.
                    </li>
                    <li style={{ marginBottom: 0 }}>
                      구독 취소 후에도 현재 결제된 기간까지는 서비스를 동일하게
                      이용하실 수 있습니다.
                    </li>
                  </ul>
                </div> */}

                <div className="ui message box-transparent">
                  <ul className="list">
                    <li>
                      유료 구독 상품은 부가가치세(10%)가 포함된 가격입니다.
                    </li>
                    <li>유료 구독 상품은 구매 후 바로 사용이 가능합니다.</li>
                    <li>
                      결제 방식과 요금에 관한 내용은{' '}
                      <Link to="/notice?no=5" className="positive-text">
                        [알림 &gt; 공지사항]
                      </Link>
                      에서 확인하실 수 있습니다.
                    </li>
                    <li>
                      구독 취소 후에도 현재 결제된 기간까지는 서비스를 동일하게
                      이용하실 수 있습니다.
                    </li>
                    <li>
                      구독 취소는{' '}
                      <Link
                        to="/usage"
                        style={{
                          color: '#4caf50',
                        }}
                      >
                        [사용정보]
                      </Link>
                      에서 가능합니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderPage === 1 && (
          <div className="row">
            {orderItems.length > 0 &&
              orderItems
                .filter((x) => x.ITEM_ID !== 3)
                .map((item, i) => {
                  return (
                    <div className="col-12 col-md-4" key={i}>
                      <div
                        className="card mb-4 overflow-hidden bg-template"
                        style={{
                          background: '#e5e7eb',
                          color: 'black',
                          border: '1px solid #D7D7D7',
                        }}
                      >
                        <div className="card-body">
                          <span
                            className="h4 mb-2 font-weight-normal"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: '24px',
                            }}
                          >
                            {item.NAME}
                          </span>
                          <div className="text-mute mt-7 text-center">
                            <span className="text-27 font-700">
                              &#8361; {this.numberWithCommas(item.PRICE)}
                            </span>
                            <span>/월</span>
                          </div>
                          <div className="mt-1 mb-0 text-center">
                            {/* {item.ITEM_ID === 6 && (
                              <span className="text-09">
                                연 {this.numberWithCommas(item.PRICE * 12)} 원
                                (월 {this.numberWithCommas(item.PRICE)} 원에
                                이용)
                              </span>
                            )} */}
                            {item.ITEM_ID === 6 && (
                              <span className="text-09">
                                매월{' '}
                                {this.numberWithCommas(
                                  item.PRICE * item.PROPERTY_ID
                                )}{' '}
                                원 결제
                              </span>
                            )}
                          </div>
                          <div className="row mt-1 mb-1">
                            <div className="col"></div>
                            <div className="col-auto cursor-pointer">
                              <span
                                className={`tag  ${
                                  item.ITEM_ID === 7 ? 'green-theme-bg' : ''
                                }`}
                                onClick={() =>
                                  this.handleOnClickShowPaymentType(item)
                                }
                                style={{
                                  backgroundColor: '#4caf50',
                                }}
                              >
                                {item.ITEM_ID === 6 && '구독하기'}
                                {item.ITEM_ID !== 6 && '구매하기'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
        {orderPage === 2 && selectedOrder.ITEM_ID !== 6 && (
          <div className="row">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading">결제정보</h4>
                    <div className="row mt-2">
                      <div className="col">
                        <span>{selectedOrder.NAME}</span>
                      </div>
                      <div className="col-auto cursor-pointer">
                        <span className="badge badge-warning">
                          &#8361;{' '}
                          {this.numberWithCommas(
                            selectedOrder.PRICE * selectedOrder.PROPERTY_ID
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading">결제수단</h4>
                    <Form>
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">신용카드</span>
                          <Form.Group className="mt-1 ml-2">
                            <Form.Radio
                              name="methods"
                              label="신용카드"
                              value="card"
                              checked={methods === 'card'}
                              onChange={this.onChangeCheckedToArray}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">간편결제</span>
                          <Form.Group inline className="mt-1 ml-2">
                            <Form.Radio
                              name="methods"
                              label="카카오페이"
                              value="kakaopay"
                              checked={methods === 'kakaopay'}
                              onChange={this.onChangeCheckedToArray}
                            />
                            <Form.Radio
                              name="methods"
                              label="PAYCO"
                              value="payco"
                              checked={methods === 'payco'}
                              onChange={this.onChangeCheckedToArray}
                            />
                            <Form.Radio
                              name="methods"
                              label="PAYPAL"
                              value="paypal"
                              checked={methods === 'paypal'}
                              onChange={this.onChangeCheckedToArray}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <Form>
                <Form.Field>
                  <Checkbox
                    name="isCheck"
                    label="위 상품의 구매 조건을 확인하였으며, 결제 진행에 동의합니다. (전자상거래법 제8조 제2항)"
                    checked={isCheck}
                    onChange={this.onChangeCheckedToBoolean}
                  />
                </Form.Field>
              </Form>
            </div>

            <div className="col-12 text-center">
              <Button
                className="mr-2"
                onClick={(e) => this.handleOnClickGoBack(e)}
              >
                <span className="mr-1">이전</span>
              </Button>
              <Button color="orange" onClick={(e) => this.onSubmit(e)}>
                <Icon name="payment" />
                <span className="mr-1">결제하기</span>
              </Button>
            </div>
          </div>
        )}
        {orderPage === 2 && selectedOrder.ITEM_ID === 6 && (
          <div className="row">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading">결제 정보</h4>
                    <div className="row mt-2">
                      <div className="col">
                        <span>{selectedOrder.NAME}</span>
                      </div>
                      <div className="col-auto cursor-pointer">
                        <span
                          className="badge badge-warning"
                          style={{
                            background: '#4CAF50',
                            color: 'white',
                          }}
                        >
                          월 &#8361;{' '}
                          {this.numberWithCommas(
                            selectedOrder.PRICE * selectedOrder.PROPERTY_ID
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading"> 수단</h4>
                    <Form>
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">신용카드</span>
                          <Form.Group className="mt-1 ml-2">
                            <Form.Radio
                              name="methods"
                              label="신용카드"
                              value="card"
                              checked={methods === 'card'}
                              onChange={this.onChangeCheckedToArray}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">간편결제</span>
                          <Form.Group
                            inline
                            className="mt-1 ml-2"
                            onClick={() => {
                              this.setState({ methods: 'kakaopay' });
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <Form.Radio
                              name="methods"
                              label=""
                              value="kakaopay"
                              checked={methods === 'kakaopay'}
                              onChange={this.onChangeCheckedToArray}
                            />
                            <img
                              width={60}
                              src="/assets/payment/payment_icon_yellow_small.png"
                              alt="kakaopay"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <Form>
                <Form.Field>
                  <Checkbox
                    name="isCheck"
                    label="위 상품의 구매 조건을 확인하였으며, 결제 진행에 동의합니다. (전자상거래법 제8조 제2항)"
                    checked={isCheck}
                    onChange={this.onChangeCheckedToBoolean}
                  />
                </Form.Field>
              </Form>
            </div>

            <div className="col-12 text-center">
              <Button
                className="mr-2"
                onClick={(e) => this.handleOnClickGoBack(e)}
              >
                <span className="mr-1">이전</span>
              </Button>
              <Button
                // color="orange"
                style={{
                  background: '#4CAF50',
                  color: 'white',
                }}
                onClick={(e) => this.onSubmit(e)}
              >
                <Icon name="payment" />
                <span className="mr-1">구매하기</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(OrderItems);
