import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Responsive, Popup, Button, Icon } from 'semantic-ui-react';
import { PaginationRoundBtn } from 'components';

import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/coupon';
import * as commonUtil from 'utils/common';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class CouponList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			couponItems: [],
			currentPage: 1,
			pageSize: 10,
			totalPage: 0,
			totalElements: 0,
		};
	}

	componentDidMount() {
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.user != this.state.user) {
			const { user, pageSize, currentPage } = this.state;
			this.fetchSelectCouponItems({
				userId: user.id,
				pageSize: pageSize,
				pageNum: currentPage,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	onClickUseCoupon = (e, couponItem) => {
		e.preventDefault();
		//console.log(couponItem);
		const user = this.state.user;

		if (user !== undefined && user !== null) {
			this.fetchUseMyCouponItem({
				userId: user.id,
				couponId: couponItem.ID,
				couponCode: couponItem.COUPON_CODE,
			});
		}
	};

	onClickGiftCoupon = (e, couponItem) => {
		e.preventDefault();
		let data = {
			couponItem: couponItem,
		};
		this.props.history.push({ pathname: '/coupon/Gift', post: data });
	};

	fetchUseMyCouponItem = async (params) => {
		const user = this.state.user;
		const { pageSize, currentPage } = this.state;
		const couponItem = await service.useMyCouponItem(params);

		if (couponItem !== null) {
			if (couponItem.status === 200) {
				Toastr.info('쿠폰이 정상적으로 사용되었습니다. 사용 내역은 사용정보를 통해 확인 바랍니다.');

				this.fetchSelectCouponItems({
					userId: user.id,
					pageSize: pageSize,
					pageNum: currentPage,
				});
			} else {
				Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	fetchSelectCouponItems = async (params) => {
		const couponItems = await service.selectMyCouponItems(params);

		if (couponItems !== null) {
			if (couponItems.status === 200) {
				if (couponItems.data) {
					this.showCouponItems(couponItems.data);
				} else {
					this.setState({
						couponItems: [],
						totalPage: 0,
						totalElements: 0,
					});
				}
			} else {
				Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	showCouponItems(data) {
		const totalPages = Math.ceil(parseInt(data.totalCount) / this.state.pageSize);
		this.setState({
			totalElements: data.totalCount,
			totalPages: totalPages,
			couponItems: data.datas,
		});
	}

  paginationChange = (activePage) => {
    //const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
    const user = this.props.user;
    if (user) {
      this.setState({ activePage: activePage, selectedIndex: 0 });
      this.fetchListAll(
        Object.assign({}, this.state, { offset: activePage, user })
      );
    }
  };

	render() {
		const { couponItems, activePage, pageSize, totalPages } = this.state;
		return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0 main-title">내 쿠폰 목록</h4>
          </div>
          <div className="col-auto align-self-end"></div>
        </div>

        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <div className="ui message box-transparent">
                  <ul className="list">
                    <li className="content">
                      발행된 쿠폰의{' '}
                      <span className="font-600 positive-text">
                        유효기간은 발행일로부터 3개월
                      </span>
                      입니다.
                    </li>
                    <li className="content">
                      사용 만료된 쿠폰은 재발행되지 않습니다.
                    </li>
                    <li className="content">
                      쿠폰이 사용된 이후에 취소 및 재발행은 불가합니다.
                    </li>
                    <li className="content">
                      사용된 쿠폰은 사용정보에서 확인 가능합니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list-group list-group-flush my-0">
          <Grid>
            <Grid.Row style={{ marginLeft: '0px' }}>
              <Grid.Column>
                <Table
                  size="small"
                  key="black"
                  fixed
                  selectable
                  singleLine
                  textAlign="center"
                  style={{
                    position: 'relative',
                    padding: '0px',
                    margin: '0px',
                  }}
                >
                  <Responsive as={Table.Header} minWidth={768}>
                    <Table.Row>
                      <Table.HeaderCell width="3">쿠폰코드</Table.HeaderCell>
                      <Table.HeaderCell width="3">쿠폰명</Table.HeaderCell>
                      <Table.HeaderCell width="3">발행일</Table.HeaderCell>
                      <Table.HeaderCell width="3">만료일</Table.HeaderCell>
                      <Table.HeaderCell width="4">비고</Table.HeaderCell>
                    </Table.Row>
                  </Responsive>
                  <Table.Body>
                    {couponItems &&
                      couponItems.map((item, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell textAlign="center">
                              {item.COUPON_CODE}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {item.WordnetCouponGroup !== undefined &&
                                item.WordnetCouponGroup !== null &&
                                item.WordnetCouponGroup.COUPON_NAME.trim()}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {moment(item.ISSUE_DATE).format('YYYY-MM-DD')}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {item.EXPIRED_DATE !== undefined &&
                                item.EXPIRED_DATE !== null &&
                                moment(item.EXPIRED_DATE).format('YYYY-MM-DD')}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {commonUtil.chkAvailableCoupon(
                                item.EXPIRED_DATE
                              ) &&
                                item.STATUS.trim() === 'USING' && (
                                  <Fragment>
                                    <Popup
                                      content="쿠폰사용하기"
                                      trigger={
                                        <Button
                                          size="small"
                                          compact
                                          color="facebook"
                                          onClick={(e) =>
                                            this.onClickUseCoupon(e, item)
                                          }
                                        >
                                          사용하기
                                        </Button>
                                      }
                                    />
                                    <Popup
                                      content="쿠폰선물하기"
                                      trigger={
                                        <Button
                                          size="small"
                                          compact
                                          onClick={(e) =>
                                            this.onClickGiftCoupon(e, item)
                                          }
                                        >
                                          선물하기
                                        </Button>
                                      }
                                    />
                                  </Fragment>
                                )}
                              {!commonUtil.chkAvailableCoupon(
                                item.EXPIRED_DATE
                              ) &&
                                item.STATUS.trim() === 'USING' && (
                                  <Fragment>
                                    <Popup
                                      content="쿠폰사용만료"
                                      trigger={
                                        <Button size="small" compact disabled>
                                          사용만료
                                        </Button>
                                      }
                                    />
                                  </Fragment>
                                )}
                              {item.STATUS.trim() === 'COMPLETE' && (
                                <Popup
                                  content="쿠폰사용하기"
                                  trigger={
                                    <Button size="small" compact disabled>
                                      사용완료
                                    </Button>
                                  }
                                />
                              )}
                              {item.STATUS.trim() === 'PRESENT' && (
                                <Popup
                                  content="쿠폰사용하기"
                                  trigger={
                                    <Button size="small" compact disabled>
                                      선물완료
                                    </Button>
                                  }
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    {couponItems && couponItems.length === 0 && (
                      <Table.Row>
                        <Table.Cell colSpan="5">
                          사용 가능한 쿠폰이 존재하지 않습니다.
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <PaginationRoundBtn
          currentPage={activePage}
          totalPage={totalPages}
          onChangePage={this.paginationChange}
        />
      </div>
    );
	}
}

export default connect(mapStateToProps, null)(withRouter(CouponList));
