import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Card, Icon } from 'semantic-ui-react';

class Company extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0 main-title">(주)낱말소개</h4>
          </div>
          <div className="col-auto align-self-end"></div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      우리말의 어휘 난이도 체계 완성(2001~2004년)
                    </span>
                    <p />
                    <Card.Description
                      content="2001년 서울대학교 국어교육연구소 김광해 교수는 우리말 50만 어휘에 7등급의 난이도를 부여하여 낱말 v.2001을 제안하였습니다.
                                            2004년 ㈜낱말에서는 v.2001에서 선정된 어휘를 교과서와 수준별 도서에 등장하는 어휘들과 비교, 분석하여 학년별로 9등급의 난이도를 부여한 v.2004 체계를 완성했습니다."
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      독서교육의 새로운 패러다임 제시(2004년~)
                    </span>
                    <p />
                    <Card.Description
                      content="축적된 어휘 난이도와 시소러스 자료를 기반으로 2004년에 이르러 도서의 난이도 분석 기술을 완성했습니다.
                                            텍스트의 어휘를 계량화하고 어휘의 난이도, 문장 길이, 문장의 복잡도, 출판물 변인 등을 분석하여 도서의 난이도를 수치로 표시하였습니다.
                                            어휘의 난이도를 기준으로 등급별 어휘 분포, 전문어 분포 등 도서별 어휘 관련 통계 정보를 함께 제공하여 도서 분류를 자동화할 수 있는 가능성을 제시하였습니다."
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      한국어 매크로 시소러스 개발(2006년)
                    </span>
                    <p />
                    <Card.Description
                      content="1987년 서울대 김광해 교수의 「유의어·반의어 사전」으로부터 시작된 유의어 반의어에 관한 연구는 2000년 ㈜낱말에서의 어휘 전산화 작업을 거치면서 본격화되었습니다.
                                            2009년에 이르러 연구 결과를 집대성한 「넓은풀이 우리말 유의어 대사전」을 발간하였습니다. "
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      인터넷 사전 및 어플 개발(2011년~)
                    </span>
                    <p />
                    <Card.Description
                      content="2000년도 이후에 인터넷 시대가 열리면서 전자사전, 인터넷사전의 필요성이 대두되어 2011년부터 우리말 활용 사전의 인터넷 서비스를 시작했습니다.
                                            2012년 이후에는 모바일 시대의 요구에 발맞추어 각종 사전의 애플리케이션 개발 및 서비스를 시작했습니다. "
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      의미 기반 유의어사전 개발(2013년~)
                    </span>
                    <p />
                    <Card.Description
                      content="㈜낱말에서 2009년도에 개발한 「넓은풀이 우리말 유의어대사전」은 1차 유의어를 2차 유의어로 확장한 형태의 사전이지만 일부 어휘는 다양한 의미를 지니기 때문에 유의어사전으로서는 부족한 점이 있었습니다.
                                            이러한 이유 때문에 기존의 유의어사전에 대한 대대적인 개편 작업이 진행되었고 2019년의 홈페이지에서 서비스할 수 있게 되었습니다."
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      문장 검사기 개발(2019년~)
                    </span>
                    <p />
                    <Card.Description
                      content="자기소개서, 서술형 시험, 논술, 보고서, 기안문서, 업무용 이메일, SNS, 블로그와 같이 이제는 누구나 글을 써야 하는 환경에 둘러싸여 있습니다.
                                            그동안 ㈜낱말에서 축적한 어휘난이도, 유의어사전, 자연어처리 기술을 총동원하고 일부는 대학교와 산학협력을 통하여 문장검사 프로그램을 개발했습니다.
                                            현재 구현되고 있는 각종 문장/어휘 통계, 맞춤법 검사 및 유의어 추천, 중복 표현, 한자 변환, 11개국 대역어 변환 등의 기능에 추가하여 문법/어법의 오류와 관련된 기능이 계속 추가될 예정입니다."
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      대용량 말뭉치 구축 및 머신러닝 기술(2019년~)
                    </span>
                    <p />
                    <Card.Description
                      content="보다 정교한 문장검사기능을 구현하기 위해서는 기존의 규칙기반 알고리즘으로는 부족한 점이 있었습니다.
                                            이를 위해서는 대규모 말뭉치가 필요한데 ㈜낱말에서는 1억 어절 이상의 도서 말뭉치와 20억 어절 이상의 신문기사 말뭉치(최근 10년치 기사)를 구축하여 활용하고 있습니다."
                    />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <Card fluid>
                  <Card.Content>
                    <span className="text-11 font-600">
                      인공지능 프로그램을 활용한 한국어 작문 평가 기술 개발
                      (2021년~)
                    </span>
                    <p />
                    <Card.Description content="작문 평가는 인간의 지적 능력과 사고력을 가늠하기 위한 좋은 수단임에도 시간과 비용적인 제약 및 채점자의 신뢰성 확보가 쉽지 않아서 그동안 활성화되지 못했습니다. 그러나 최근에 AI(인공지능) 기법을 활용하여 이 문제를 극복할 수 있는 길이 열림에 따라 ㈜낱말에서는 현재까지 구축했던 한국어의 의미 데이터를 활용하여 한국어 작문 평가 프로그램을 개발하고 있습니다.  " />
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Company;
