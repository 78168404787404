import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Icon, Image } from 'semantic-ui-react';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="row no-gutters">
          <div className="col-auto">
            {/* <div className="btn-menu menu-btn ml-3 my-2"> */}
            <div className="btn-menu menu-btn ml-3 my-3">
              <Icon name="bars" className="menu colorWhite" />
              <Icon
                size="large"
                name="arrow alternate circle left outline"
                className="closeicon colorWhite"
              />
            </div>
          </div>
          <div className="col text-left">
            <div className="header-logo my-2">
              <Link to="/" className="cursor-pointer">
                <Image
                  style={{
                    width: '80px',
                    height: '30px',
                  }}
                  // src="/assets/images/logos/Logo_50_C.png"
                  src="/assets/images/logos/wordnet-logo.png"
                  size="tiny"
                  alt=""
                  className="header-logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-auto">
            <Link to="/notice" className="btn btn-link">
              <Icon name="alarm" />
              <span className="counts">1</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
