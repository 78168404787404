import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Table, Button, Responsive, Pagination, Grid, Segment, Icon, Input, Dropdown, Label, Popup } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

const loading = {
	load: false,
};

class MgrUser extends Component {
    
    constructor(props) {
        super(props);

        let searchOptStatus = systemTypes.SEARCH_OPT_USER_STATUS_LABEL.map(key => {
			let item = systemTypes.SEARCH_OPT_USER_STATUS.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        let searchOptCol = systemTypes.SEARCH_OPT_COLNAME_LABEL.map(key => {
			let item = systemTypes.SEARCH_OPT_COLNAME.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
		});

        this.state = {
            loading,
            searchOptStatus,
            searchOptCol,
            userStatus: '',
            userCol: '',
            searchName: '',
            resultData: [],
            currentPage: 1,
            pageSize: 10,
            totalPage: 0,
            totalElements: 0,
            totalUser: 0,
            totalPaid: 0,
            totalFree: 0
        }
    }

    componentDidMount() {
        const user = this.props.user;
        //console.log(this.props);
        if(user !== undefined && user !== null && user.grade === 0) {
            this.fetchSelectUsersInfo({
                userStatus: this.state.userStatus, 
                userCol: this.state.userCol, 
                searchName: this.state.searchName,
                pageSize: this.state.pageSize, 
                pageNum: this.state.currentPage
            });
        }
    }

    handleOnClickStatus = (e, { name, value }) => {

		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
        }
        
        this.setState({ isWarning: false });        
    };

    handleOnClickCol = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });		
    };
    
    searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchKeywordOnClick(e);
		}
    };

    searchKeywordOnClick = e => {
		e.preventDefault();
		this.searchKeywordHandler();
	};
    
    searchKeywordHandler = () => {
		const { pageSize, userStatus, userCol, searchName, loading, currentPage } = this.state;

		loading['load'] = true;
		this.setState({ loading });

		this.fetchSelectUsersInfo({ 
            userStatus: userStatus, 
            userCol: userCol,
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: currentPage, 
        });
    };	

    paginationChange = (e, { activePage }) => {
		const { pageSize, userStatus, userCol, searchName } = this.state;
		this.setState({ currentPage: activePage });
		this.fetchSelectUsersInfo({             
            userStatus: userStatus, 
            userCol: userCol, 
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: activePage, 
        });
	};
    
    onInputTextChange = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };
    
    onClickDetail = (e, userItem) => {
        e.preventDefault();
        //console.log(userItem);
        let data = { userItem };
		this.props.history.push({ pathname: '/mgrUser/UserDetail/' + userItem.ID, post: data });
    };
    
    onClickOrder = (e, userItem) => {
        e.preventDefault();
        //console.log(userItem);
        let data = { userItem };
		this.props.history.push({ pathname: '/mgrUser/OrderDetail/' + userItem.ID, post: data });
	};

    fetchSelectUsersInfo = async(params) => {

        const userItems = await service.selectUserItems(params);
        //console.log(userItems);

        if(userItems !== null) {            
            if(userItems.status === 200) {
                if(userItems.data) {
                    this.showUserItems(userItems.data);    
                } else {
                    this.setState({            
                        resultData: [],
                        totalPage: 0,
                        totalElements: 0
                    });
                }             
            } else {
                Toastr.error('사용자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('사용자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        }   
        
        let { loading } = this.state;
		loading['load'] = false;
		this.setState({ loading });
    }

    showUserItems(data) {
        const totalPage = Math.ceil(parseInt(data.searchTotal) / this.state.pageSize);
        //if (this._isMounted) {
            this.setState({ 
                totalElements: data.searchTotal, 
                totalUser: data.userTotal,
                totalPaid: data.paidTotal,
                totalFree: data.freeTotal,
                totalPages: totalPage, 
                resultData: data.datas 
            });
        //}
    }

    render() {
        const { match } = this.props;
        const { resultData, currentPage, totalPages, totalElements, totalUser, totalPaid, totalFree } = this.state;
        const { isWarning, loading, searchName, searchOptStatus, searchOptCol, userStatus, userCol } = this.state;
        //console.log(match);
        return (
          <div className="container my-5">
            <div className="row mb-4">
              <div className="col">
                <h4 className="mb-0 main-title">회원관리</h4>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 mb-4">
                <Form warning={isWarning}>
                  <Grid>
                    <Grid.Row style={{ marginLeft: '0px' }}>
                      <Grid.Column>
                        <Segment color="black" clearing>
                          <Form.Field inline>
                            <Form.Group widths={4} style={{ margin: 'auto' }}>
                              <Form.Field width={4}>
                                <Dropdown
                                  selection
                                  name="userStatus"
                                  options={searchOptStatus}
                                  placeholder="회원상태"
                                  onChange={this.handleOnClickStatus}
                                  value={userStatus}
                                />
                              </Form.Field>
                              <Form.Field width={4}>
                                <Dropdown
                                  selection
                                  name="userCol"
                                  options={searchOptCol}
                                  placeholder="검색대상"
                                  onChange={this.handleOnClickCol}
                                  value={userCol}
                                />
                              </Form.Field>
                              <Form.Field width={8}>
                                <Input
                                  name="searchName"
                                  fluid
                                  action={{
                                    size: 'tiny',
                                    color: 'grey',
                                    children: '검색',
                                    onClick: this.searchKeywordOnClick,
                                  }}
                                  icon="search"
                                  placeholder="검색명"
                                  value={searchName}
                                  onKeyPress={this.searchOnKeyPress}
                                  onChange={this.onInputTextChange}
                                  loading={loading.group}
                                  iconPosition="left"
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form.Field>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        marginLeft: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid.Column>
                        <Grid columns="equal">
                          <Grid.Column>
                            <Button as="div" labelPosition="right">
                              <Button icon>검색결과</Button>
                              <Label as="a" basic pointing="left">
                                {totalElements}
                              </Label>
                            </Button>
                          </Grid.Column>
                          <Grid.Column textAlign="right">
                            <Label.Group color="blue">
                              <Label as="a">
                                전체
                                <Label.Detail>{totalUser}</Label.Detail>
                              </Label>
                              <Label as="a">
                                유료
                                <Label.Detail>{totalPaid}</Label.Detail>
                              </Label>
                              <Label as="a">
                                무료
                                <Label.Detail>{totalFree}</Label.Detail>
                              </Label>
                            </Label.Group>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ marginLeft: '0px' }}>
                      <Grid.Column>
                        <Table
                          key="black"
                          fixed
                          selectable
                          textAlign="center"
                          style={{
                            position: 'relative',
                            padding: '0px',
                            margin: '0px',
                          }}
                        >
                          <Responsive as={Table.Header} minWidth={768}>
                            <Table.Row>
                              <Table.HeaderCell width="2">No.</Table.HeaderCell>
                              <Table.HeaderCell width="4">
                                이메일
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                사용자명
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                사용등급
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                가입일
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center" width={4} />
                            </Table.Row>
                          </Responsive>
                          <Table.Body>
                            {resultData &&
                              resultData.length > 0 &&
                              resultData.map((user, index) => {
                                let userType;

                                if (
                                  user.STATUS &&
                                  user.STATUS.trim() === 'START'
                                ) {
                                  userType = '기본';
                                } else {
                                  userType = '무료';
                                }

                                return (
                                  <Table.Row
                                    key={'user_' + index}
                                    className="cursor-pointer"
                                  >
                                    <Table.Cell>{user.ID}</Table.Cell>
                                    <Table.Cell textAlign="left">
                                      {user.USEREMAIL}
                                    </Table.Cell>
                                    <Table.Cell singleLine>
                                      {user.USERNAME}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {userType === '무료' && (
                                        <Label color="grey">{userType}</Label>
                                      )}
                                      {userType === '기본' && (
                                        <Label color="orange">{userType}</Label>
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {moment(user.CREATED_DATE).format(
                                        'YYYY-MM-DD'
                                      )}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      <Popup
                                        content="상세보기"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickDetail(e, user)
                                            }
                                          >
                                            <Icon name="search plus" />
                                          </Button>
                                        }
                                      />
                                      <Popup
                                        content="주문정보"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickOrder(e, user)
                                            }
                                          >
                                            <Icon name="payment" />
                                          </Button>
                                        }
                                      />
                                      <Popup
                                        content="사전이용정보"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickDic(e, user)
                                            }
                                          >
                                            <Icon name="book" />
                                          </Button>
                                        }
                                      />

                                      <Popup
                                        content="사용자삭제"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickDelete(e, user)
                                            }
                                          >
                                            <Icon name="user cancel" />
                                          </Button>
                                        }
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            {(resultData === undefined ||
                              resultData === null ||
                              resultData.length === 0) && (
                              <Table.Row>
                                <Table.Cell colSpan="6">
                                  회원 정보가 존재하지 않습니다.{' '}
                                </Table.Cell>
                              </Table.Row>
                            )}
                            <Table.Row>
                              <Table.Cell colSpan="6" className="text-center">
                                {resultData && resultData.length > 0 && (
                                  <Pagination
                                    activePage={currentPage}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    boundaryRange={0}
                                    siblingRange={2}
                                    pointing
                                    secondary
                                    onPageChange={this.paginationChange}
                                    totalPages={totalPages}
                                  />
                                )}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </div>
          </div>
        );
    }
}

export default connect(mapStateToProps, null)(MgrUser);