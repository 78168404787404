import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Accordion, Icon, Label, Button } from 'semantic-ui-react'
import style from './noticelist.module.css';
class NoticeList extends Component {
  constructor(props) {
    super(props);
    const search = this.props.history.location.search;
    const searchParams = search.replace(/[?no=]/g, '');

    this.state = {
      activeIndex: searchParams ? Number(searchParams) : 6,
    };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleOnClickMakeIcon = (e) => {
    e.preventDefault();
    this.props.history.push('/notice/MakeIcon');
  };

  handleOnClickShowNatmal = (e) => {
    e.preventDefault();
    window.open('https://www.natmal.com', '_blank');
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0">공지사항</h4>
          </div>
          <div className="col-auto align-self-end"></div>
        </div>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 6}
            index={6}
            onClick={this.handleClick}
            style={{
              position: 'relative',
            }}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor-event"
            >
              공지
            </Label>
            <span>서비스명 변경 안내 (기본유의어사전 → 한국어 워드넷사전)</span>
            <span
              style={{
                position: 'absolute',
                right: '20px',
              }}
            >
              {' '}
              (2025년 1월 1일부터 적용)
            </span>
          </Accordion.Title>

          <Accordion.Content
            active={activeIndex === 6}
            style={{
              padding: '0px 0px 1em 0px',
            }}
          >
            <div className="">
              <ul
                style={{
                  listStyleType: 'none',
                  margin: '0px',
                }}
              >
                <li style={{ padding: '10px' }}>
                  <ul
                    style={{
                      listStyleType: 'none',
                    }}
                  >
                    낱말닷컴(www.natmal.com)에서 서비스 중인 ‘유의어사전’과
                    기존의 '기본유의어사전'의 서비스명이 비슷해서 발생하는
                    고객의 혼동을 방지하기 위해서 www.wordnet.co.kr 사이트에서
                    제공하던 '기본유의어사전 (A Dictionary of Synonyms)'의
                    서비스명이 2025년 1월 1일부터 '한국어 워드넷사전 (A Korean
                    Wordnet Dictionary)'으로 변경되었습니다.
                  </ul>
                </li>
                <li style={{ padding: '10px' }}>
                  <ul
                    style={{
                      listStyleType: 'none',
                    }}
                  >
                    <li>
                      <p>
                        낱말닷컴(www.natmal.com)의 ‘유의어사전’은 문형을
                        고려하여 어휘의 의미를 27,000여 개로 범주화한 사전인
                        반면, '한국어 워드넷사전'은 비슷한말, 반대말, 높임말,
                        낮춤말, 상위어, 하위어, 관련어 등 11개 카테고리로
                        구분되어 의미상 유사하거나 반대되거나 관련된 어휘들을
                        포괄적으로 제공합니다. 또한 '한국어 워드넷사전'은 텍스트
                        위주의 경량 사전으로, 스마트폰에서도 사용이 편리합니다.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 5}
            index={5}
            onClick={this.handleClick}
            style={{
              position: 'relative',
            }}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor-event"
            >
              공지
            </Label>
            <span>결제 방식 및 요금 변경 안내</span>
            <span
              style={{
                position: 'absolute',
                right: '20px',
              }}
            >
              {' '}
              (2025년 1월 1일부터 적용)
            </span>
          </Accordion.Title>

          <Accordion.Content
            active={activeIndex === 5}
            style={{
              padding: '0px 0px 1em 0px',
            }}
          >
            <div className="">
              <ul
                style={{
                  listStyleType: 'none',
                  padding: '0 0 0 9px',
                }}
              >
                <li style={{ padding: '10px' }}>
                  <h5 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    ■ 변경 사항
                  </h5>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: '0 0 0 9px',
                    }}
                  >
                    <li className={style.payment_info}>
                      <p>
                        • 기간별 일시불결제 → 월 단위 정기구독 방식으로 전환
                      </p>
                      <p>• 기본 정기구독권: 월 ￦3,000원</p>
                    </li>
                  </ul>
                </li>
                <li style={{ padding: '10px' }}>
                  <h5 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    ■ 정기구독 신청 방법
                  </h5>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: '0 0 0 9px',
                    }}
                  >
                    <li className={style.payment_info}>
                      <p>
                        •{' '}
                        <a
                          href="/payment"
                          style={{
                            color: 'green',
                          }}
                        >
                          [로그인 &gt; 구독권 구매 &gt; 구독하기]
                        </a>
                        에서 신청 가능
                      </p>
                    </li>
                  </ul>
                </li>

                <li style={{ padding: '10px' }}>
                  <h5 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    ■ 기존 이용권 사용자를 위한 안내
                  </h5>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: '0 0 0 9px',
                    }}
                  >
                    <li className={style.payment_info}>
                      <p> • 이용권 만료 시점까지 기존 서비스 이용 가능</p>
                      <p> • 이용권 만료 후 새로운 정기구독권 신청</p>
                    </li>
                  </ul>
                </li>

                <li style={{ padding: '10px' }}>
                  <h5 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    ■ 정기구독 해지 안내
                  </h5>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: '0 0 0 9px',
                    }}
                  >
                    <li className={style.payment_info}>
                      <p>
                        •{' '}
                        <a
                          href="/usage"
                          style={{
                            color: 'green',
                          }}
                        >
                          [마이페이지 &gt; 사용 정보]
                        </a>
                        에서 사용 내역의 '구독취소' 버튼으로 구독 해지 가능
                      </p>
                      <p>• 해지 시 구독 기간 종료까지 서비스 이용 가능</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 4}
            index={4}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor"
            >
              공지
            </Label>{' '}
            모든 앱 서비스 업데이트 및 판매 종료 안내
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            <div className="pl-32 pr-32">
              그동안 낱말의 앱(아이폰 및 안드로이드용 모바일 앱) 서비스를 이용해
              주신 고객님들께 감사드립니다.
              <p />
              <p />
              모바일 앱은 안드로이드 10 및 iOS 13 이상의 OS 버전에서는 더 이상
              지원이 불가하며, 향후에도 업데이트 및 수정이 어렵습니다. 그래서
              낱말에서 제공했던{' '}
              <span className="text-highlight-orangered">
                모든 앱에 대해 업데이트 및 판매를 중지
              </span>
              하게 되었습니다.
              <p />
              <p />
              낱말은 모바일 앱을 대신할
              '기본유의어사전(https://m.wordnet.co.kr)'이라는 모바일 사용에
              최적화된 웹서비스를 개발하여 오픈하였습니다. 앞으로 낱말의 모든
              사전 검색 서비스는 낱말닷컴(낱말창고)과 기본유의어사전 사이트를
              통해서만 이용이 가능합니다. <p />
              <p />
              지금까지 낱말의 앱을 이용해 주신 고객님들께 다시 한번 진심으로
              감사드립니다.
              <p />
              <p />
              <br />
              <p />
              <p />
              <span className="text-highlight-orangered font-500">
                [환불 및 보상 규정]
              </span>
              <p />
              <b># 앱을 구입한 지 1년 이내인 경우:</b> 전액 환불 또는
              '기본유의어사전' 1년 이용권(&#8361;21,600원) 중 선택
              <br />
              <b># 앱을 구입한 지 1년이 경과한 경우:</b> 기본유의어사전 3개월
              이용권(&#8361;7,500원) 제공
              <p />
              <p />
              환불 및 보상을 위해서{' '}
              <span className="text-highlight-blue font-500">
                앱 구매내역(주문번호, 주문날짜)과 환불/보상 여부를 함께 적어서
                master@natmal.com으로
              </span>{' '}
              보내주시기 바랍니다. 앱 구매내역은 구입하신 앱스토어나 구입 당시
              발송된 구매확인 메일로 확인하실 수 있습니다. 해당 정보는
              개인정보이므로 낱말에서는 확인이 불가합니다.
              <p />
              <p />
              &nbsp;&nbsp;<b>- 환불을 요청할 경우:</b> 구매내역 확인 후 환불
              처리.
              <br />
              &nbsp;&nbsp;<b>- 보상을 요청할 경우:</b>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
              '기본유의어사전(https://m.wordnet.co.kr)' 사이트에 직접 회원가입
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 구매내역과 가입한 아이디를
              함께 이메일로 발송
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 구매내역 확인 후 해당
              아이디에 이용권 제공
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 로그인 후 쿠폰함에 있는
              이용권 사용
              <p />
              <p />
              이와 관련하여 문의사항이 있으신 경우 아래의 연락처로 전화주시기
              바랍니다.
              <br />
              앞으로도 더 나은 서비스 제공을 위해 노력하겠습니다.
              <p />
              <p />
              감사합니다.
              <p />
              <p />
              # 낱말 고객센터: 02-3463-2321 / master@natmal.com
              <p />
              <p />
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor"
            >
              공지
            </Label>{' '}
            기본유의어사전 오픈 안내
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <div className="pl-32 pr-32">
              낱말닷컴에서 제공하는 유의어사전 서비스와 별도로{' '}
              <span className="text-highlight-orangered font-500">
                새로운 유의어사전 서비스를 3월 10일에 오픈
              </span>
              합니다. <p />이 서비스는{' '}
              <span className="text-highlight-orangered font-500">
                스마트폰에서 웹브라우저를 통해서도 간편하게 유의어를 검색
              </span>
              할 수 있습니다. <p />
              많은 이용과 관심 부탁드립니다. <p />
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor"
            >
              공지
            </Label>{' '}
            (구)낱말창고 서비스 종료 안내
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <div className="pl-32 pr-32">
              그동안 낱말창고를 이용해 주신 고객님들께 감사드립니다. 현재 운영
              중인 낱말창고 서비스는{' '}
              <span className="text-highlight-orangered font-500">
                2020년 3월 10일에 종료
              </span>
              할 예정입니다. <p />
              구글과 MS에서 더 이상 플래시를 지원하지 않아 사이트 운영 및 보안에
              문제가 발생할 것으로 예상되어 부득이하게 사이트 운영 종료를
              결정하였습니다. 이 점 양해 바랍니다. <p />
              (구)낱말창고에서 제공하는 서비스는 현재{' '}
              <span className="text-highlight-orangered font-500">
                <span onClick={this.handleOnClickShowNatmal}>
                  낱말닷컴(www.natmal.com)
                </span>
                에서 업그레이드하여 서비스
              </span>
              하고 있습니다.
              <p />
              많은 이용 바랍니다. <p />
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor"
            >
              공지
            </Label>{' '}
            (구)낱말창고 통합 안내
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <div className="pl-32 pr-32">
              (구)낱말창고의 서비스가 종료됨에 따라{' '}
              <span className="text-highlight-orangered font-500">
                기존에 낱말창고를 유료로 이용하시는 사용자
              </span>
              분들을 위해{' '}
              <span className="text-highlight-orangered font-500">
                간단한 통합 절차
              </span>
              를 거쳐{' '}
              <span className="text-highlight-orangered font-500">
                남은 기간 +1개월 만큼 기본유의어사전 이용
              </span>
              하실 수 있도록{' '}
              <span className="text-highlight-orangered font-500">
                전환서비스를 실시
              </span>
              하고 있습니다.
              <p />본 전환서비스는{' '}
              <span className="text-highlight-orangered font-500">
                이용기간이 만료되었거나 '신규낱말닷컴(www.natmal.com)'으로
                전환을 하신 경우엔 제외
              </span>
              됩니다. <p />
              ※ 통합을 위해 아래의 절차에 따라 진행해 주시기 바랍니다. <p />
              &nbsp;&nbsp;1. 기본유의어사전 사이트에 회원 가입 (이메일 인증
              필요)
              <p />
              &nbsp;&nbsp;2. 로그인 후 좌측 상단의 메뉴 선택
              <p />
              &nbsp;&nbsp;3. 자신의 이메일 주소를 클릭하여 사용자 정보로 이동
              <p />
              &nbsp;&nbsp;4. (구)낱말창고 아이디/비밀번호 입력 후 통합 승인
              <p />
              해당 조치에 관해 문의사항이 있는 사용자께서는 02-3463-2321로 전화
              주시기 바랍니다. <p />
              감사합니다.
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Label
              size="medium"
              horizontal
              className="color-white main-notice-bgcolor"
            >
              공지
            </Label>{' '}
            모바일기기에서 바로가기 아이콘 만드는 법
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div className="pl-32 pr-32">
              모바일기기에서 기본유의어사전 접속을 위한 아이콘을 간단하게 홈
              화면에 추가할 수 있습니다. <p />
              기본유의어사전{' '}
              <span className="text-highlight-orangered font-500">
                아이콘을 만들면 주소 입력 없이 쉽고 편리하게 접속
              </span>
              하여 이용하실 수 있습니다.
              <p />
              만드는 방법은 아래의 버튼을 클릭하시면 확인하실 수 있습니다. <p />
              <div className="text-center">
                <Button color="teal" onClick={this.handleOnClickMakeIcon}>
                  <Icon name="plus" />
                  <b>바로가기 아이콘 만드는 방법</b>
                </Button>
              </div>
            </div>
          </Accordion.Content>
        </Accordion>

        {/*
                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
                        <Icon name='dropdown' />
                        <Label size='medium' horizontal className='color-white main-notice-bgcolor'>공지</Label> 친구추천 이벤트 
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                        <div className='pl-32 pr-32'>
                            낱말닷컴에서 제공하는 유의어사전 서비스와 별도로 <span className='text-highlight-orangered font-500'>새로운 유의어사전 서비스를 오픈</span>합니다. <p/>
                            이 서비스는 <span className='text-highlight-orangered font-500'>스마트폰에서 웹브라우저를 통해서도 편하게 유의어를 검색</span>할 수 있습니다. <p/>
                            많은 이용과 관심 부탁드립니다. <p/>
                        </div>
                    </Accordion.Content> 
                </Accordion>        
*/}
      </div>
    );
  }
}

export default withRouter(NoticeList);