export const MID_TEST_KCP = 'A52LD';
export const MID_PROD_KCP = 'IP178';
export const MID_TEST_KAKAOPAY = 'TCSUBSCRIP';
export const MID_PROD_KAKAOPAY = 'CASOXRPDRV';
export const CHANNEL_TEST_KCP =
  'channel-key-4a9e4b5d-67bc-4043-8208-dd97e3e98735';
export const CHANNEL_PROD_KCP =
  'channel-key-52c20dfa-c861-4a3b-890d-4050de5648e9';
export const CHANNEL_TEST_KAKAOPAY =
  'channel-key-69ad2363-12a4-40f2-a543-66c2b41d319c';
export const CHANNEL_PROD_KAKAOPAY =
  'channel-key-f25a9545-d82c-4af4-9096-4f92d5719691';

export const KAKAOPAY = 'kakaopay';
export const KCP = 'kcp';
export const TEST_KCP = 'TEST_kcp';
export const KCP_BILLING = 'kcp_billing';
export const TEST_KCP_BILLING = 'TEST_kcp_billing';
export const TEST_KAKAOPAY = 'TEST_kakaopay';

export const PAYMENT_LIST = {
  kakaopay: '카카오페이',
  kcp: 'NHN KCP',
  TEST_kcp: 'TEST_KCP',
  kcp_billing: 'NHN KCP 정기결제',
  TEST_kcp_billing: 'TEST_KCP_정기결제',
  TEST_kakaopay: 'TEST_카카오페이',
};

export const METHOD_CARD = 'card';
export const METHOD_KAKAOPAY = 'kakaopay';

export const PAYMETHOD = {
  card: '신용카드',
  kakaopay: '카카오페이',
};
