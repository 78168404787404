import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Table, Button, Responsive, Pagination, Grid, Segment, Icon, Input, Dropdown, Label, Popup } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

const loading = {
	load: false,
};

class MgrCoupon extends Component {
    
    constructor(props) {
        super(props);

        let searchOptStatus = systemTypes.COUPON_STATUS_LABEL.map(key => {
			let item = systemTypes.COUPON_STATUS.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        this.state = {
            loading,
            searchOptStatus,
            couponStatus: '',
            searchName: '',
            resultData: [],
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0
        }
    }

    componentDidMount() {
        const user = this.props.user;
        //console.log(this.props);
        if(user !== undefined && user !== null && user.grade === 0) {
            this.fetchSelectCouponInfo({
                couponStatus: this.state.couponStatus, 
                searchName: this.state.searchName,
                pageSize: this.state.pageSize, 
                pageNum: this.state.currentPage
            });
        }
    }

    handleOnClickStatus = (e, { name, value }) => {

		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
        }
        
        this.setState({ isWarning: false });        
    };

    handleOnClickCouponCreate = e => {
		e.preventDefault();        
		this.props.history.push('/mgrCoupon/Create');
	};

    searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchKeywordOnClick(e);
		}
    };

    searchKeywordOnClick = e => {
		e.preventDefault();
		this.searchKeywordHandler();
    };
    
    searchKeywordHandler = () => {
		const { pageSize, couponStatus,searchName, loading, currentPage } = this.state;

		loading['load'] = true;
		this.setState({ loading });

		this.fetchSelectCouponInfo({ 
            couponStatus: couponStatus, 
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: currentPage, 
        });
    };

    paginationChange = (e, { activePage }) => {
		const { pageSize, couponStatus, searchName } = this.state;
		this.setState({ currentPage: activePage });
		this.fetchSelectCouponInfo({             
            couponStatus: couponStatus, 
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: activePage, 
        });
    };
    
    onInputTextChange = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };

    onClickCouponList = (e, couponGroupItem) => {
        e.preventDefault();
        //console.log(couponGroupItem);
        //let data = { couponGroupItem };
        //this.props.history.push({ pathname: '/mgrCoupon/List', post: data });
        this.props.setCouponGroupItem(couponGroupItem, 1);
        this.props.history.push({pathname: '/mgrCoupon/List'});
    };
    
    onClickCouponEdit = (e, couponItem) => {
        e.preventDefault();
        //console.log(couponItem);
        let data = { couponItem };
		this.props.history.push({ pathname: '/mgrCoupon/Edit' + couponItem.ID, post: data });
    };

    onClickCouponDelete = (e, couponItem) => {
        e.preventDefault();
    };
    
    fetchSelectCouponInfo = async(params) => {

        const couponItems = await service.selectCouponGroupItems(params);
        //console.log(couponItems);

        if(couponItems !== null) {            
            if(couponItems.status === 200) {
                if(couponItems.data) {
                    this.showCouponItems(couponItems.data);    
                } else {
                    this.setState({            
                        resultData: [],
                        totalPages: 0,
                        totalElements: 0
                    });
                }             
            } else {
                Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        }   
        
        let { loading } = this.state;
		loading['load'] = false;
		this.setState({ loading });
    }

    showCouponItems(data) {
        const totalPages = Math.ceil(parseInt(data.totalCount) / this.state.pageSize);
        //if (this._isMounted) {
            this.setState({ 
                totalElements: data.totalCount,              
                totalPages: totalPages, 
                resultData: data.datas 
            });
        //}
    }

    render() {
        const { resultData, currentPage, totalPages, totalElements } = this.state;
        const { isWarning, loading, searchName, searchOptStatus, couponStatus } = this.state;


        return (
          <div className="container">
            <div className="row mb-4">
              <div className="col">
                <h4 className="mb-0 main-title">쿠폰관리</h4>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 mb-4">
                <Form warning={isWarning}>
                  <Grid>
                    <Grid.Row style={{ marginLeft: '0px' }}>
                      <Grid.Column>
                        <Segment color="black" clearing>
                          <Form.Field inline>
                            <Form.Group widths={4} style={{ margin: 'auto' }}>
                              <Form.Field width={4}>
                                <Dropdown
                                  selection
                                  name="couponStatus"
                                  options={searchOptStatus}
                                  placeholder="쿠폰상태"
                                  onChange={this.handleOnClickStatus}
                                  value={couponStatus}
                                />
                              </Form.Field>
                              <Form.Field width={8}>
                                <Input
                                  name="searchName"
                                  fluid
                                  action={{
                                    size: 'tiny',
                                    color: 'grey',
                                    children: '검색',
                                    onClick: this.searchKeywordOnClick,
                                  }}
                                  icon="search"
                                  placeholder="쿠폰명"
                                  value={searchName}
                                  onKeyPress={this.searchOnKeyPress}
                                  onChange={this.onInputTextChange}
                                  loading={loading.group}
                                  iconPosition="left"
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form.Field>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        marginLeft: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid.Column>
                        <Grid columns="equal">
                          <Grid.Column>
                            <Button as="div" labelPosition="right">
                              <Button icon>검색결과</Button>
                              <Label as="a" basic pointing="left">
                                {totalElements}
                              </Label>
                            </Button>
                          </Grid.Column>
                          <Grid.Column textAlign="right">
                            <Button
                              size="medium"
                              children="쿠폰 그룹 생성"
                              className="positive-background"
                              onClick={this.handleOnClickCouponCreate}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ marginLeft: '0px' }}>
                      <Grid.Column>
                        <Table
                          key="black"
                          fixed
                          selectable
                          textAlign="center"
                          style={{
                            position: 'relative',
                            padding: '0px',
                            margin: '0px',
                          }}
                        >
                          <Responsive as={Table.Header} minWidth={768}>
                            <Table.Row>
                              <Table.HeaderCell width="2">No.</Table.HeaderCell>
                              <Table.HeaderCell width="4">
                                쿠폰종류명
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                쿠폰개수
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                사용개수
                              </Table.HeaderCell>
                              <Table.HeaderCell width="2">
                                생성일
                              </Table.HeaderCell>
                              <Table.HeaderCell textAlign="center" width={4} />
                            </Table.Row>
                          </Responsive>
                          <Table.Body>
                            {resultData &&
                              resultData.length > 0 &&
                              resultData.map((coupon, index) => {
                                return (
                                  <Table.Row
                                    key={'coupon_' + index}
                                    className="cursor-pointer"
                                  >
                                    <Table.Cell>{coupon.ID}</Table.Cell>
                                    <Table.Cell textAlign="left">
                                      {coupon.COUPON_NAME}
                                    </Table.Cell>
                                    <Table.Cell singleLine>
                                      {coupon.TOTAL_COUNT}
                                    </Table.Cell>
                                    <Table.Cell>{coupon.USED_COUNT}</Table.Cell>
                                    <Table.Cell>
                                      {moment(coupon.CREATE_DATE).format(
                                        'YYYY-MM-DD'
                                      )}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      <Popup
                                        content="쿠폰목록"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickCouponList(e, coupon)
                                            }
                                          >
                                            <Icon name="list" />
                                          </Button>
                                        }
                                      />
                                      <Popup
                                        content="쿠폰수정"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickCouponEdit(e, coupon)
                                            }
                                          >
                                            <Icon name="edit" />
                                          </Button>
                                        }
                                      />
                                      <Popup
                                        content="쿠폰삭제"
                                        trigger={
                                          <Button
                                            icon
                                            circular
                                            compact
                                            onClick={(e) =>
                                              this.onClickCouponDelete(
                                                e,
                                                coupon
                                              )
                                            }
                                          >
                                            <Icon name="delete" />
                                          </Button>
                                        }
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            {(resultData === undefined ||
                              resultData === null ||
                              resultData.length === 0) && (
                              <Table.Row>
                                <Table.Cell colSpan="6">
                                  생성된 쿠폰이 존재하지 않습니다.{' '}
                                </Table.Cell>
                              </Table.Row>
                            )}
                            <Table.Row>
                              <Table.Cell colSpan="6" className="text-center">
                                {resultData && resultData.length > 0 && (
                                  <Pagination
                                    activePage={currentPage}
                                    ellipsisItem={{
                                      content: (
                                        <Icon name="ellipsis horizontal" />
                                      ),
                                      icon: true,
                                    }}
                                    firstItem={{
                                      content: (
                                        <Icon name="angle double left" />
                                      ),
                                      icon: true,
                                    }}
                                    lastItem={{
                                      content: (
                                        <Icon name="angle double right" />
                                      ),
                                      icon: true,
                                    }}
                                    prevItem={{
                                      content: <Icon name="angle left" />,
                                      icon: true,
                                    }}
                                    nextItem={{
                                      content: <Icon name="angle right" />,
                                      icon: true,
                                    }}
                                    boundaryRange={0}
                                    siblingRange={2}
                                    pointing
                                    secondary
                                    onPageChange={this.paginationChange}
                                    totalPages={totalPages}
                                  />
                                )}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </div>
            </div>
          </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCouponGroupItem: actions.setCouponGroupItem   
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MgrCoupon));